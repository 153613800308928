import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import {MatTabsModule} from '@angular/material/tabs';



import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Interceptor } from './interceptor.service';

import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzIconModule } from 'ng-zorro-antd/icon';    



import { AppComponent } from './app.component';
import { TempComponent } from './temp/temp.component';
import { Temp2Component } from './temp2/temp2.component';
import { HomeverticalsliderComponent } from './homeverticalslider/homeverticalslider.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AssetclassComponent } from './assetclass/assetclass.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
import { SlidertestingComponent } from './slidertesting/slidertesting.component';
import { CareersComponent } from './careers/careers.component';
import { SubcareersComponent } from './subcareers/subcareers.component';
import { FundsComponent } from './funds/funds.component';
import { FundpageComponent } from './fundpage/fundpage.component';
import { WatchlistComponent } from './watchlist/watchlist.component';
import { ExpertiseComponent } from './expertise/expertise.component';
import { InsightsComponent } from './insights/insights.component';

import { GlobalnetworkComponent } from './globalnetwork/globalnetwork.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { GuideInvestorsComponent } from './guide-investors/guide-investors.component';
import { GuideMapComponent } from './guide-map/guide-map.component';
import { from, Observable } from 'rxjs';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'homeverticalslider', component: HomeverticalsliderComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'assetclass', component: AssetclassComponent },
  { path: 'whoweare', component: WhoweareComponent },
  { path: 'slidertesting', component: SlidertestingComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'subcareers', component: SubcareersComponent },
  { path: 'funds', component: FundsComponent },
  { path: 'fundpage', component: FundpageComponent },
  { path: 'watchlist', component: WatchlistComponent },
  { path: 'expertise', component: ExpertiseComponent },
  { path: 'insights', component: InsightsComponent },
  { path: 'globalnetwork', component: GlobalnetworkComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'guideinvestors', component: GuideInvestorsComponent },
  { path: 'guidemap', component: GuideMapComponent },


  { path: 'ez-edkhar1', component: Temp2Component },
  { path: 'ez-edkhar', component: TempComponent },
];
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    let langLocal = localStorage.getItem("lang");
    if (langLocal == undefined) {
      localStorage.setItem("lang", "en");
    } else {
      localStorage.setItem("lang", langLocal);
    }
    return from(import(`../assets/i18n/${localStorage.getItem("lang")}.json`));
  }
}
@NgModule({
  declarations: [
    AppComponent,
    TempComponent,
    Temp2Component,
    HomeverticalsliderComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    AssetclassComponent,
    WhoweareComponent,
    SlidertestingComponent,
    CareersComponent,
    SubcareersComponent,
    FundsComponent,
    FundpageComponent,
    WatchlistComponent,
    ExpertiseComponent,
    InsightsComponent,
    GlobalnetworkComponent,
    PrivacyComponent,
    TermsComponent,
    GuideInvestorsComponent,
    GuideMapComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    NzTabsModule,
    NzSwitchModule,
    IvyCarouselModule,
    NzCollapseModule,
    NzCarouselModule,
    Ng5SliderModule,    
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    NzIconModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzToolTipModule,
    MatTabsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(["en", "ar"]);
    this.translateService.setDefaultLang("en");
    this.translateService.use("en");
  }
}