import { Component, OnInit } from '@angular/core';
import { WhoweareService } from './service/whoweare.service';
import { Milestone } from './data/data';
import { TopClients } from './data/data';
import { Headers } from './data/data';
import { Directors } from './data/data';

import * as $ from 'jquery';
window['jQuery'] = window['$'] = $;

@Component({
  selector: 'app-whoweare',
  templateUrl: './whoweare.component.html',
  styleUrls: ['./whoweare.component.css'],
})
export class WhoweareComponent implements OnInit {
  effect = 'scrollx';

  constructor(private _milestoneApiService: WhoweareService) {}
  slides: any = [[]];
  slidesclinet: any = [[]];
  slidesdirector: any = [[]];
  intervalNumber = 0;
  chunk(arr: any, chunkSize: number) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  loader: boolean = true;

  public counter: number;
  public counterr: number;

  listmilestone: Milestone[];

  listClients: TopClients[];

  listDirectors: Directors[];

  listHeaders: Headers;

  ngOnInit(): void {
    if (window.innerWidth > 879) {
      this.counter = 5;
    } else {
      this.counter = 1;
    }

    if (window.innerWidth > 879) {
      this.counterr = 5;
    } else {
      this.counterr = 1;
    }

    this._milestoneApiService.getmilestone().subscribe((data) => {
      this.loader = true;
      if (data.status.code == 200) {
        this.loader = false;
        this.listmilestone = data.data;
        console.log(data.data.length)
        this.slides = this.chunk(data.data, this.counter);
        if (data.data.length <= 5) {
          this.intervalNumber = 0
        }
        else{
          this.intervalNumber = 5000

        }
      }
    });

    this._milestoneApiService.gettopclients().subscribe((data) => {
      if (data.status.code == 200) {
        this.listClients = data.data;
        this.slidesclinet = this.chunk(data.data, this.counter);
      }
    });

    this._milestoneApiService.getheaders().subscribe((data) => {
      if (data.status.code == 200) {
        this.listHeaders = data.data;
      }
    });

    this._milestoneApiService.getdirectors().subscribe((data) => {
      if (data.status.code == 200) {
        this.listDirectors = data.data;
        this.slidesdirector = this.chunk(data.data, this.counterr);
      }
    });
  }

  setSlider() {
    setTimeout(() => {
      this.test();
      let element: HTMLElement = document.getElementsByClassName(
        'cascade-slider_dot'
      )[0] as HTMLElement;
      element.click();
    }, 1000);
  }

  test = () => {
    (<any>$.fn).cascadeSlider = function (opt: {
      itemClass: string;
      arrowClass: string;
    }) {
      var itemClass = opt.itemClass || 'cascade-slider_item',
        arrowClass = opt.arrowClass || 'cascade-slider_arrow',
        $item = $(this).find('#cascade-slider .cascade-slider_item'),
        $arrow = $(this).find('.' + arrowClass),
        itemCount = $(this).length;

      var defaultIndex = 0;

      changeIndex(defaultIndex);

      $arrow.on('click', function () {
        var action = $(this).data('action'),
          nowIndex = $item.index($(this).find('.now'));
        if (action == 'next') {
          if (nowIndex == itemCount - 1) {
            changeIndex(0);
          } else {
            changeIndex(nowIndex + 1);
          }
        } else if (action == 'prev') {
          if (nowIndex == 0) {
            changeIndex(itemCount - 1);
          } else {
            changeIndex(nowIndex - 1);
          }
        }

        $('.cascade-slider_dot').removeClass('cur');
        //$('.cascade-slider_dot').next().addClass('cur');
      });

      // add data attributes
      for (var i = 0; i < itemCount; i++) {
        $('.cascade-slider_item').each(function (i: any) {
          $(this).attr('data-slide-number', i);
        });
      }

      // dots
      $('.cascade-slider_dot').on('click', function () {
        // add class to current dot on click
        $('.cascade-slider_dot').removeClass('cur');
        $(this).addClass('cur');

        var index = $(this).index();

        $('.cascade-slider_item').removeClass('now prev next');
        var slide = $('.cascade-slider_slides').find(
          '[data-slide-number=' + index + ']'
        );
        slide.prev().addClass('prev');
        slide.addClass('now');
        slide.next().addClass('next');

        if (slide.next().length == 0) {
          $('.cascade-slider_item:first-child').addClass('next');
        }

        if (slide.prev().length == 0) {
          $('.cascade-slider_item:last-child').addClass('prev');
        }
      });

      function changeIndex(nowIndex: number) {
        // clern all class
        $(this).find('.now').removeClass('now');
        $(this).find('.next').removeClass('next');
        $(this).find('.prev').removeClass('prev');
        // if (nowIndex == itemCount - 1) {
        // $item.eq(0).addClass('next');
        // }

        // if (nowIndex == 0) {
        // console.log($('#cascade-slider .cascade-slider_item'))
        // console.log($item.eq(itemCount))
        // console.log("2222222")
        // $item.eq(itemCount - 1).addClass('prev');
        // }

        // setTimeout(() => {
        // console.log(".cascade-slider_dot")
        // }, 500);

        $item.each(function (index: number) {
          if (index == nowIndex) {
            $item.eq(index).addClass('now');
          }
          if (index == nowIndex + 1) {
            $item.eq(index).addClass('next');
          }
          if (index == nowIndex - 1) {
            $item.eq(index).addClass('prev');
          }
        });
      }
    };

    /*================================= */
    (<any>$('#cascade-slider')).cascadeSlider({
      itemClass: 'cascade-slider_item',
      arrowClass: 'cascade-slider_arrow',
    });
  };
}
