import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guide-investors',
  templateUrl: './guide-investors.component.html',
  styleUrls: ['./guide-investors.component.css']
})
export class GuideInvestorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
