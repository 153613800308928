import { Component, OnInit } from '@angular/core';
import { ReportsService } from './service/reports.service';
import { Reports } from './data/data';
import { News } from './data/data';
import { videos } from './data/data';
import { DomSanitizer } from '@angular/platform-browser';
import { Headers } from './data/data';
import { Awards } from './data/data';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css'],
})
export class InsightsComponent implements OnInit {
  constructor(
    private _reportApiService: ReportsService,
    private sanitizer: DomSanitizer
  ) {}

  loader: boolean = true;

  listReports: Reports[];

  listNews: News[];

  listAwards: Awards[];

  listVideos: videos[];

  slides: any = [[]];

  newsSlides: any = [[]];

  awardsSlides: any = [[]];

  listHeaders: Headers;
  selectedTab: number = 0;
  x:any = ''

  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  hello(){
    setTimeout(() => {
      let indicator =  document.getElementsByClassName('carousel-indicators')[0]
      let numofIndicators = indicator.childElementCount
      for(let i = 1; i  <= numofIndicators; i++   ){
        let children = indicator.children[i-1]; 
        this.x = JSON.stringify(i)
        children.innerHTML = '<span>'+this.x+'</span>'
        console.log(indicator)
      }

    }, 2000);
  }

  ngOnInit(): void {
    this.hello()

    var urlParams = new URLSearchParams(window.location.search);
    let tab = urlParams.get('tab');
    this.selectedTab = parseInt(tab);
    this._reportApiService.getReports().subscribe((data) => {
      if (data.status.code == 200) {
        this.listReports = data.data;
        this.slides = this.chunk(this.listReports, 8);
      }
    });

    this._reportApiService.getNews().subscribe((data) => {
      if (data.status.code == 200) {
        this.listNews = data.data;
        this.newsSlides = this.chunk(this.listNews, 8);
      }
    });

    this._reportApiService.getVideos().subscribe((data) => {
      this.loader = true;

      if (data.status.code == 200) {
        this.loader = false;

        this.listVideos = data.data;
        this.listVideos.map((objvideo) => {
          // console.log(objvideo);
          objvideo.link = this.sanitizer.bypassSecurityTrustResourceUrl(
            objvideo.link
          );
          return objvideo;
        });
      }
    });
    this._reportApiService.getheaders().subscribe((data) => {
      if (data.status.code == 200) {
        this.listHeaders = data.data;
      }
    });

    this._reportApiService.getawards().subscribe((data) => {
      if (data.status.code == 200) {
        this.listAwards = data.data;
        this.awardsSlides = this.chunk(this.listAwards, 8);
        this.hello()
      }
    });
 

  }
}
