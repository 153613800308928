import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TermsService } from './service/terms.service';
import { terms } from './data/data';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  constructor(private _termsApiService: TermsService) {}
  loader: boolean = true;
  listterms: terms[];

  ngOnInit(): void {
    window.scrollTo(0,0);

    this._termsApiService.getterms().subscribe((data) => {
      this.loader = true;

      if (data.status.code == 200) {
        this.loader = false;

        this.listterms = data.data.terms.content;
        console.log(data.data)
      }
      // console.log(data.data)
    });
  }
}
