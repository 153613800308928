<div class="page-cont">


<div class="row zeromargin">
  <div class="col-12 pagepadding fixed">
    <a href=""><img class="logosize" src="./assets/images/logo.png" alt=""></a>
    <div  class="rightfloat top20 hideinmedia">
      <a href="https://twitter.com/AzimutEgypt" target="_blank"><img class="smsize" src="./assets/images/Group 72.svg" alt=""></a>
      <a href="https://www.facebook.com/AzimutEgypt" target="_blank"><img class="smsize" src="./assets/images/Group 74.svg" alt=""></a>
      <a href="https://www.instagram.com/azimutegypt" target="_blank"><img class="smsize" src="./assets/images/Group 75.svg" alt=""></a>
      <a href="https://www.linkedin.com/company/azimut-egypt" target="_blank"><img class="smsize" src="./assets/images/Group 80.svg" alt=""></a>
    </div>

    <div class="rightfloat showinmedia x menubuttonplace">
      <button class="menubutton" onclick="myFunction()"> <img src="./assets/images/menu icon.svg" alt=""></button>
      <div id="myDIV" class="rightfloat topp20 y">
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://twitter.com/AzimutEgypt" target="_blank"><img class="smsize11" src="./assets/images/Group 72 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://www.facebook.com/AzimutEgypt" target="_blank"><img class="smsize1" src="./assets/images/Group 74 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://www.instagram.com/azimutegypt" target="_blank"><img class="smsize11" src="./assets/images/Group 75 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://www.linkedin.com/company/azimut-egypt" target="_blank"><img class="smsize111" src="./assets/images/Group 80 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<div class="row zeromargin">
  <div class="col-lg-6 col-md-9 col-sm-12 col-12 page2padding">
    <p class="header">Azimut Egypt Asset Management</p>
    <p class="parag top30">Azimut Egypt is part of Azimut Group <a href="https://www.azimut-group.com/">www.azimut-group.com</a>, which is one of the largest European Investment Management firms managing EUR 57
      billion globally.</p>
    <p class="parag">In Egypt, Azimut is one of the largest asset management companies with over 20 years of experience with Assets Under Management exceeding
      EGP 7 billion</p>

      <a href="./ez-edkhar"><button class="buttonstyle mtop30" type="button" name="button">AZ - ادخـــــــار <img class="mright20" src="./assets/images/Right Arrow.svg" alt=""></button></a>
  </div>
</div>

</div>

<div class="row zeromargin whitecolor">
  <div class="col-12">
  </div>
</div>

<div class="row zeromargin darkblue">
  <div class="col-12 ">


<div class="row zeromargin footerpadding">

    <div class="col-lg-2 col-md-12 col-sm-12 col-12">
      <a href=""><img class="logo2size" src="./assets/images/logo.png" alt=""></a>
    </div>
    <div class="col-lg-3 col-md-10 col-sm-10 col-10 rtop20">
      <div class="row zeromargin">
        <div class="col-12">
          <img class="infosize" src="./assets/images/Path 85.svg" alt="">
          <span class="infostyle">Address</span>
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 top10">
          <p class="infodetails right70">Smart Village | Building B 16 | PO Box 12577 | Giza, Egypt</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
      <div class="row zeromargin">
        <div class="col-12">
          <img class="infosize" src="./assets/images/Path 67.svg" alt="">
          <span class="infostyle">phone number</span>
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 top10">
          <a href="tel:+20 235353600"><p class="infodetails">+20 235353600</p></a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
      <div class="row zeromargin">
        <div class="col-12">
          <img class="infosize" src="./assets/images/Group 119.svg" alt="">
          <span class="infostyle">E-mail</span>
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 top10">
          <a href="mailto:i@azimut.eg"><p class="infodetails">i@azimut.eg</p></a>
        </div>
      </div>
    </div>

  </div>

  <div class="row zeromargin">
    <div class="col-12 sidepadding">
      <div class="borderbottom">
      </div>
    </div>
  </div>

  <div class="row zeromargin">
    <div class="col-12 center top15">
      <span class="footertext">Copyright Azimut © All Rights Reserved.</span>
    </div>
  </div>

  </div>
</div>
