<app-header></app-header>

<div class="loader"  *ngIf="loader"><i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i></div>

<div class="careers-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">{{'AZIMUT CAREERS' | translate}}</p>
      <p class="subheadertext center">
        {{'KNOW-HOW, EXPERIENCE AND UNCONDITIONAL VALUES' | translate}}
      </p>
    </div>
  </div>
</div>

<div class="row zeromargin" dir="{{'dir'|translate}}">
  <div class="col-12 sides310 tb50 textdirection" >
    <div class="row zeromargin">
      <div class="col-lg-9 col-md-12 col-sm-12 col-12 zeropadding">
        <div class="row zeromargin">
          <div class="col-12">
            <p class="black38 mbot4">{{ listeachcareers?.job }}</p>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-lg-4 col-md-12 col-sm-12 col-12 Rtop15">
            <img src="./assets/images/pin.svg" alt="" />
            <span class="details left5">{{ listeachcareers?.location }}</span>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop15">
            <img src="./assets/images/dollar-symbol.svg" alt="" />
            <span class="details left5">{{ listeachcareers?.salary }}</span>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop15">
            <img src="./assets/images/date.svg" alt="" />
            <span class="details left5">{{ listeachcareers?.timing }}</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-12 col-sm-12 col-12">
        <button
          class="buttonstyle3"
          (click)="toform()"
          type="button"
          name="button"
        >
          {{'Apply Now' | translate}}
        </button>
      </div>
    </div>

    <div class="row zeromargin">
      <div class="col-12 topbot46">
        <p class="gray171" [innerHTML]="listeachcareers?.desc"></p>
      </div>
    </div>

    <div class="row zeromargin">
      <div class="col-12">
        <p class="black18b">{{'Responsibilities' | translate}}:</p>
        <div
          class="row zeromargin top5"
          *ngFor="let respon of listeachcareers?.responsibilities; let i = index"
        >
          <div class="col-12 Rleft0 top10">
            <img src="./assets/images/Group 5320.svg" alt="" />
            <span class="gray17" [innerHTML]="respon"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="row zeromargin">
      <div class="col-12 top46">
        <p class="black18b">{{'Requirements' | translate}}:</p>
        <div
          class="row zeromargin top5"
          *ngFor="let respon of listeachcareers?.requirements; let i = index"
        >
          <div class="col-12 Rleft0 top10">
            <img src="./assets/images/Group 5326.svg" alt="" />
            <span class="gray17" [innerHTML]="respon"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<form enctype="multipart/form-data" class="textdirection" [formGroup]="registerForm" dir="{{'dir'|translate}}">
  <div class="row zeromargin blackbg">
    <div class="col-12 applypadding">
      <div class="whitebox" id="applyform">
        <p class="blue30B center">{{'APPLY FOR THIS JOB' | translate}}</p>
        <div class="row zeromargin">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 top44">
            <p class="black13 mbot11">{{'FIRST NAME' | translate}}</p>
            <input
              [(ngModel)]="Submit.first_name"
              id="first_name"
              class="inputsyle"
              name="first_name"
              placeholder="{{'First Name' | translate}}"
              formControlName="name"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 top44">
            <p class="black13 mbot11">{{'LAST NAME' | translate}}</p>
            <input
              [(ngModel)]="Submit.last_name"
              class="inputsyle"
              type="text"
              name=""
              placeholder="{{'Last Name' | translate}}"
              formControlName="name"
            />
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 top33">
            <p class="black13 mbot11">{{'EMAIL' | translate}}</p>
            <input
              [(ngModel)]="Submit.email"
              class="inputsyle"
              type="text"
              name=""
              placeholder="{{'Email' | translate}}"
              formControlName="email"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 top33">
            <p class="black13 mbot11">{{'MOBILE PHONE' | translate}}</p>
            <input
            [(ngModel)]="Submit.phone"

              class="inputsyle"
              type="text"
              name=""
              placeholder="{{'Mobile Phone' | translate}}"
              formControlName="phone"

            />
          </div>
        </div>
        <div class="row zeromargin top33">
          <div class="col-12">
            <p class="black13 mbot11">{{'RESUME/CV' | translate}}</p>
            <div class="row zeromargin">
              <div class="col-12 zeropadding eqheight">
                <!-- <input class="uploadinput" type="text" name="" value="" placeholder="Choose File" formControlName="cv"> -->
                <label class="uploadinput zeromargin" *ngIf="this.fileName.length == 0">{{'Choose File' | translate}}</label>
                <label class="uploadinput zeromargin" *ngIf="this.fileName.length != 0">{{this.fileName}}</label>

                <!-- <input formControlName="cv" type="text" readonly> -->
                <div class="uploadbutton relative">
                  <input formControlName="cv" class="hiddeninput" (change)="fileReader($event)" type="file" >
                  <button class="uploadbutton shownbutton" ><img src="./assets/images/upload.svg" alt="" /></button>
                </div>

                  <!-- <input (change)="docsUploadFunc($event)" type="file" class="upload up" id="up"   /> -->
                <!-- </button> -->

              </div>
            </div>
          </div>
        </div>
        <div class="row zeromargin top33">
          <div class="col-12">
            <p class="black13 mbot11">{{'MESSAGE' | translate}}</p>
            <textarea
            [(ngModel)]="Submit.message"
              placeholder="{{'Tell me more about your resume' | translate}}"
              class="textareastyle"
              name="name"
              rows="4"
              cols="80"
              formControlName="name"

            ></textarea>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12 center mtop80">
            <button
              (click)="insertuserr()"
              class="submitbutton"
              type="button"
              name="button"
            >
              {{'SUBMIT APPLICATION' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<app-footer></app-footer>
