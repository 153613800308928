<div class="row zeromargin" dir="{{ 'dir' | translate }}">
  <div class="col-12 zeropadding fixed1">
    <div class="hideinmedia">
      <div class="row zeromargin bluebackground sidepadding paddingtb">
        <div class="col-6 aligncenter zeropadding">
          <img
            class="arabicrightfloat earthsize"
            src="./assets/images/earth-grid-symbol.svg"
            alt=""
          />
          <span class="left13 vtext arabicrightfloat">{{
            "Visit" | translate
          }}</span>
          <a href="https://www.azimut-group.com/" target="_blank"
            ><span class="borderright paddingRL vtext2 arabicrightfloat">{{
              "AZIMUT GROUP" | translate
            }}</span></a
          >
          <a href="https://www.azimutinvestments.com/" target="_blank"
            ><span class="right12 vtext2 arabicrightfloat">{{
              "AZIMUT INVESTMENTS" | translate
            }}</span></a
          >
        </div>
        <div class="col-6 rightfloat zeropadding lang_holder">
          <span (click)="switchtoEn()" class="langtext en">EN - </span
          ><span (click)="switchtoAr()" class="paddingRL2 langtext borderright">AR</span>
          <a href="https://azinvest.azimut.eg">
            <button class="buttonstyle" type="button" name="button" >
              {{ "LOGIN" | translate }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="header_holder sidepadding paddingtb blackbackground hideinmedia1"
  dir="{{ 'dir' | translate }}"
>
  <div class="right_header">
    <div class="logo_holder">
      <a href=""
        ><img class="logosize" src="{{ 'logoimg' | translate }}" alt=""
      /></a>

      
    </div>

    <div class="navigation_holder">
      <nav>
        <a href="./whoweare"
          ><p class="fs12">{{ "Who we are" | translate }}</p></a
        >

        <ul>
          <li>
            <a href="./expertise" class="fs12 height40"
              >{{ "OUR EXPERTISE" | translate }}
              <img class="left5" src="./assets/images/Group 4770.svg" alt=""
            /></a>
            <!-- First Tier Drop Down -->
            <ul style="background-color: #171717">
              <br />
              <li>
                <a href="./expertise?tab=0" class="dropdown">{{
                  "FUND MANAGEMENT" | translate
                }}</a>
              </li>
              <li>
                <a href="./expertise?tab=1" class="dropdown">{{
                  "Private Pension Funds" | translate
                }}</a>
              </li>
              <li>
                <a href="./expertise?tab=2" class="dropdown">{{
                  "Multi Family Office services" | translate
                }}</a>
              </li>
              <li>
                <a href="./expertise?tab=3" class="dropdown">{{
                  "PORTFOLIO MANAGEMENT" | translate
                }}</a>
              </li>
            </ul>
          </li>
        </ul>

        <a href="./funds"
          ><p class="fs12">{{ "OUR FUNDS" | translate }}</p></a
        >

        <ul>
          <li>
            <a href="./insights" class="fs12 height40"
              >{{ "INSIGHTS" | translate }}
              <img class="left5" src="./assets/images/Group 4770.svg" alt=""
            /></a>
            <!-- First Tier Drop Down -->
            <ul style="background-color: #171717">
              <br />
              <li>
                <a href="./insights?tab=0" class="dropdown">{{
                  "AWARDS" | translate
                }}</a>
              </li>
              <li>
                <a href="./insights?tab=1" class="dropdown">{{
                  "Code of Ethics" | translate
                }} {{
                  "&" | translate
                }} {{
                  "CSR" | translate
                }}</a>
              </li>
              <!-- <li>
                <a href="./insights?tab=2" class="dropdown">{{
                  "Code of Ethics" | translate
                }}</a>
              </li> -->

              <li>
                <a href="./insights?tab=2" class="dropdown">{{
                  "REPORTS" | translate
                }}</a>
              </li>
              <li>
                <a href="./insights?tab=3" class="dropdown">{{
                  "MEDIA" | translate
                }}</a>
              </li>
              <li>
                <a href="./insights?tab=4" class="dropdown">{{
                  "NEWS" | translate
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="left_header">


    <div>
      <div
        *ngIf="openSearch"
        class="col-lg-6 col-md-12 col-sm-12 col-12 header_search_holder"
      >
        <div class="row zeromargin">
          <div class="col-4 zeropadding select_holder">
            <select
              class="selectstyle2 white11SB"
              [(ngModel)]="applyFilter.fundOption"
              name=""
            >
              <option class="white13B" value="fund_name">
                {{ "FUND NAME" | translate }}
              </option>
              <option class="white13B" value="ric">
                {{ "RIC" | translate }}
              </option>
              <option class="white13B" value="bbg">
                {{ "BBG" | translate }}
              </option>
              <option class="white13B" value="subscription">
                {{ "SUBSCRIPTION" | translate }}
              </option>
              <option class="white13B" value="redemption">
                {{ "REDMPTION" | translate }}
              </option>
            </select>
          </div>

          <div class="col-8 zeropadding">
            <div class="border1">
              <div class="row zeromargin">
                <div class="col-9">
                  <input
                    class="inputsyle"
                    type="text"
                    name=""
                    value=""
                    [(ngModel)]="applyFilter.fundText"
                    placeholder="{{ 'Search' | translate }}"
                  />
                </div>
                <div class="col-2" style="padding: 0">
                  <button
                    class="submit"
                    (click)="applyFilterMethod()"
                    type="button"
                    name="button"
                  >
                    {{ "SEARCH" | translate }}
                  </button>
                </div>
                <div class="col-1 closePopup">
                  <p (click)="closeSearch()">
                    <img src="../../assets/images/close.svg" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header_holder_s">
        <div class="box_holder">
          <div
            *ngIf="!removeSearch"
            class="box center"
            (click)="openSearchMethod()"
          >
            <img class="center" src="./assets/images/Icon-zoom.svg" alt="" />
            <p class="fs9 center">{{ "Search Funds" | translate }}</p>
          </div>

          <div class="right5">
            <a class="watch_list_holder" href="./watchlist"
              ><img class="left24" src="./assets/images/star.svg" alt="" />
              <span class="left13 fs12">{{
                "MY WATCHLIST" | translate
              }}</span></a
            >
          </div>
          <div class="circle center">
            <p class="black">{{ listNumber }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="showinmedia">
  <div
    *ngIf="openSearch"
    class="col-lg-6 col-md-12 col-sm-12 col-12 header_search_holder media_serch"
  >
    <div class="row zeromargin">
      <div class="col-3 zeropadding select_holder">
        <select
          class="selectstyle2 white11SB"
          [(ngModel)]="applyFilter.fundOption"
          name=""
        >
          <option class="white13B" value="fund_name">
            {{ "FUND NAME" | translate }}
          </option>
          <option class="white13B" value="ric">
            {{ "RIC" | translate }}
          </option>
          <option class="white13B" value="bbg">
            {{ "BBG" | translate }}
          </option>
          <option class="white13B" value="subscription">
            {{ "SUBSCRIPTION" | translate }}
          </option>
          <option class="white13B" value="redemption">
            {{ "REDMPTION" | translate }}
          </option>
        </select>
      </div>

      <div class="col-8 zeropadding">
        <div class="border1">
          <div class="row zeromargin">
            <div class="col-8">
              <input
                class="inputsyle"
                type="text"
                name=""
                value=""
                [(ngModel)]="applyFilter.fundText"
                placeholder="{{ 'Search' | translate }}"
              />
            </div>
            <div class="col-2" style="padding: 0">
              <button
                class="submit"
                (click)="applyFilterMethod()"
                type="button"
                name="button"
              >
                {{ "SEARCH" | translate }}
              </button>
            </div>
            <div class="col-1 closePopup">
              <p (click)="closeSearch()">
                <img src="../../assets/images/close.svg" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row zeromargin blackbackground highindex showinmedia height55 Rtop150 fixedmenu"
  dir="{{ 'dir' | translate }}"
>
  <div class="col-12 sidepadding">
    <div class="row zeromargin">
      <div class="col-1 aligncol">
        <div class="rightfloat1 x menubuttonplace highindex">
          <button class="menubutton" onclick="myFunction()">
            <img src="./assets/images/Group 5586.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="col-6">
        <a href=""
        ><img *ngIf="lang =='AR'" class="logosize" src="./assets/images/logo.png" alt=""
      /></a>
      <a href=""
        ><img *ngIf="lang =='EN'" class="logosize" src="./assets/images/logo.png" alt=""
      /></a>
        <!-- <a href=""
          ><img class="logosize" src="./assets/images/whitelogo.png" alt=""
        /></a> -->
      </div>
      <div class="col-4">
        <div class="row star_holder_responsive">
          <div class="col-6 borderright1 nopaddingright">
            <div class="mtop4">
              <a href="./watchlist"
                ><img class="starwidth" src="./assets/images/star.svg" alt=""
              /></a>
            </div>
          </div>

          <div class="col-6 Rmtop4" *ngIf="!removeSearch">
            <div class="box center" (click)="openSearchMethod()">
              <img class="center" src="./assets/images/Icon-zoom.svg" alt="" />
              <p class="fs9 center">{{ "Search Funds" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="myDIV"
      class="rightfloat1 topp20 y maxheight boxstyle highindex menuplace"
    >
      <div class="row zeromargin">
        <div class="col-6">
          <a href="https://azinvest.azimut.eg">
            <button class="buttonstyle" type="button" name="button" >
              {{ "LOGIN" | translate }}
            </button>
          </a>
        </div>
        <div class="col-5 textright lang_holder">
          <span (click)="switchtoEn()" class="langtext en">EN - </span
          ><span (click)="switchtoAr()" class="paddingRL2 langtext">AR</span>
        </div>
        <div class="col-12 Rtop15">
          <a href="./whoweare"
            ><p class="fs12">{{ "Who we are" | translate }}</p></a
          >
        </div>
        <div class="col-12 Rtop15 headermenu">
          <nz-collapse>
            <nz-collapse-panel nzHeader="{{ 'OUR EXPERTISE' | translate }}">
              <a href="./expertise?tab=0"
                ><p class="uppdercase">
                  {{ "FUND MANAGEMENT" | translate }}
                </p></a
              >
              <a href="./expertise?tab=1"
                ><p class="uppdercase">
                  {{ "Private Pension Funds" | translate }}
                </p></a
              >
              <a href="./expertise?tab=2"
                ><p class="uppdercase">
                  {{ "Multi Family Office services" | translate }}
                </p></a
              >
              <a href="./expertise?tab=3"
                ><p class="uppdercase">
                  {{ "PORTFOLIO MANAGEMENT" | translate }}
                </p></a
              >
            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <div class="col-12 Rtop15">
          <a href="./funds"
            ><p class="fs12">{{ "OUR FUNDS" | translate }}</p></a
          >
        </div>
        <div class="col-12 Rtop15 headermenu">
          <nz-collapse>
            <nz-collapse-panel nzHeader="{{ 'INSIGHTS' | translate }}">
              <a href="./insights?tab=0"
                ><p class="uppdercase">{{ "AWARDS" | translate }}</p></a
              >
              <a href="./insights?tab=1"
                ><p class="uppdercase">
                  {{ "Code of Ethics" | translate }} {{ "&" | translate }} {{ "CSR" | translate }}
                </p></a
              >
              <!-- <a href="./insights?tab=2"
                ><p class="uppdercase">
                  {{ "Code of Ethics" | translate }}
                </p></a
              > -->
              <a href="./insights?tab=2"
                ><p class="uppdercase">{{ "REPORTS" | translate }}</p></a
              >
              <a href="./insights?tab=3"
                ><p class="uppdercase">{{ "MEDIA" | translate }}</p></a
              >
              <a href="./insights?tab=4"
                ><p class="uppdercase">{{ "NEWS" | translate }}</p></a
              >
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <div
        class="row zeromargin bluebackground sidepadding paddingtb bluesectionposition"
      >
        <div class="col-12 zeropadding">
          <div class="row zeromargin">
            <span class="vtext arabicrightfloat">{{
              "Visit" | translate
            }}</span>
          </div>
          <div class="row zeromargin Rtop20">
            <img
              class="arabicrightfloat"
              src="./assets/images/earth-grid-symbol.svg"
              alt=""
            />
            <a href="https://www.azimut-group.com/" target="_blank"
              ><span class="paddingRL vtext2 arabicrightfloat">{{
                "AZIMUT GROUP" | translate
              }}</span></a
            >
          </div>
          <div class="row zeromargin Rtop20">
            <img
              class="arabicrightfloat"
              src="./assets/images/earth-grid-symbol.svg"
              alt=""
            />
            <a href="https://www.azimutinvestments.com/" target="_blank"
              ><span class="right12 vtext2">{{
                "AZIMUT INVESTMENTS" | translate
              }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
