<section class="slide-wrapper">
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li
        data-target="#myCarousel"
        *ngFor="let item of listslider; let i = index"
        attr.data-slide-to="{{i}}"
        [ngClass]="{ active: i == 0 }"
      ></li>
    </ol>

    <!-- Wrapper for slides -->
    <div class="slider-cont">
      <div class="carousel-inner">
        <div
          class="carousel-item"
          *ngFor="let item of listslider; let i = index"
          [ngClass]="{ active: i == 0 }"
        >
          <div class="fill center">
            <!-- <p class="center Mtop80 text1">{{ "LOOK AT INVESTING FROM" | translate }}</p> -->
            <p class="center text2 Mtop80">{{ item.title }}</p>
            <a target="_blank" href="{{ item.document }}"
              ><button class="buttonstyle Mtop40" type="button" name="button">
                {{ "FIND OUT MORE" | translate }}
              </button></a
            >
            <!-- <a *ngIf="item.slug == 'reports'" href="./insights?tab=2"
              ><button class="buttonstyle Mtop40" type="button" name="button">
                {{ "FIND OUT MORE" | translate }}
              </button></a
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
