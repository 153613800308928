import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PrivacyService } from './service/privacy.service';
import { privacy } from './data/data';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css'],
})
export class PrivacyComponent implements OnInit {
  constructor(private _privacyApiService: PrivacyService) {}
  loader: boolean = true;
  listprivacy: privacy;

  ngOnInit(): void {
    window.scrollTo(0,0);

    this._privacyApiService.getprivacy().subscribe((data) => {
      this.loader = true;

      if (data.status.code == 200) {
        this.loader = false;

        this.listprivacy = data.data.privacy.content;
        console.log(
          data.data.privacy
        )
      }
      // console.log(data.data)
    });
  }
}
