import { Component, OnInit } from '@angular/core';
// import { NzFormTooltipIcon } from 'ng-zorro-antd/form';
// import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-slidertesting',
  templateUrl: './slidertesting.component.html',
  styleUrls: ['./slidertesting.component.css']
})
export class SlidertestingComponent implements OnInit {
//   validateForm!: FormGroup;
//   captchaTooltipIcon: NzFormTooltipIcon = {
//     type: 'info-circle',
//     theme: 'twotone'
//   };

//   submitForm(): void {
//     for (const i in this.validateForm.controls) {
//       this.validateForm.controls[i].markAsDirty();
//       this.validateForm.controls[i].updateValueAndValidity();
//     }
//   }

//   updateConfirmValidator(): void {
//     /** wait for refresh value */
//     Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
//   }

//   confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
//     if (!control.value) {
//       return { required: true };
//     } else if (control.value !== this.validateForm.controls.password.value) {
//       return { confirm: true, error: true };
//     }
//     return {};
//   };

//   getCaptcha(e: MouseEvent): void {
//     e.preventDefault();
//   }
  constructor() { }
   ngOnInit(): void {
//     this.validateForm = this.fb.group({
//       email: [null, [Validators.email, Validators.required]],
//       password: [null, [Validators.required]],
//       checkPassword: [null, [Validators.required, this.confirmationValidator]],
//       nickname: [null, [Validators.required]],
//       phoneNumberPrefix: ['+86'],
//       phoneNumber: [null, [Validators.required]],
//       website: [null, [Validators.required]],
//       captcha: [null, [Validators.required]],
//       agree: [false]
//     });
   }

}
