<div class="row zeromargin darkgraybg rowpadding">
  <div class="col-12 center1">
    <span class="asset">{{ "ASSET" | translate }}</span
    ><span class="left5 text1"> {{ "CLASS" | translate }}</span>
  </div>
</div>

<div class="">
  <!-- <nz-tabset class="sliderdirection">
    <nz-tab
      *ngFor="let item of listAssets; let i = index"
      nzTitle="{{ item.name }}"
    >
      <div class="row zeromargin ">
        <div class="col-12 centerrr ">
          <span class="blue21">{{'ABOUT' | translate}} </span
          ><span class="left5 fs21">{{ item.name }}</span>
          <p class="centerrr side200 fs14">{{ item.description }}</p>
        </div>
      </div>
    </nz-tab>
  </nz-tabset> -->

  <mat-tab-group mat-align-tabs="center" dir="{{ 'dir' | translate }}">
    <mat-tab
      *ngFor="let item of listAssets; let i = index"
      label="{{ item.name }}"
    >
      <div class="row zeromargin semiblackbg">
        <div class="col-12 centerrr top15 ">
          <span class="blue21">{{ "ABOUT" | translate }} </span
          ><span class="left5 fs21">{{ item.name }}</span>
          <p class="centerrr side200 fs14">{{ item.description }}</p>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
