import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public applyFilter: any = {
    assetName: '',
    fundOption: 'fund_name',
    fundText: '',
  };

  public langSelector: any = true;
  public removeSearch = false;
  public lang: any;
  listNumber: number = 0;
  openSearch = false;
  constructor(
    public http: HttpClient,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (window.location.href.indexOf('funds') > -1) {
      this.removeSearch = true;
    } else {
      this.removeSearch = false;
    }
    this.listNumber = JSON.parse(localStorage.getItem('list')).length;
    this.lang = localStorage.getItem('lang');
    this.lang = this.lang.toUpperCase();

    if (
      localStorage.getItem('lang') != null &&
      localStorage.getItem('lang') != undefined
    ) {
      this.translate.use(localStorage.getItem('lang'));

      if (localStorage.getItem('lang') == 'en') {
        this.langSelector = true;
      } else {
        this.langSelector = false;
      }
    } else {
      this.translate.use('en');

      localStorage.setItem('lang', 'en');
    }
  }

  applyFilterMethod() {
    this.router.navigate([
      '/funds',
      {
        fundText: this.applyFilter.fundText,
        fundOption: this.applyFilter.fundOption,
      },
    ]);
  }

  openSearchMethod() {
    this.openSearch = true;
  }
  closeSearch() {
    this.openSearch = false;
  }

  switchLang(lang: any) {
    lang = !lang;
    if (lang == true) {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    } else {
      this.translate.use('ar');
      localStorage.setItem('lang', 'ar');
    }
    this.langSelector = lang;
    // console.log(lang);
    // location.reload();
  }
  switchtoAr() {
    this.translate.use('ar');
    localStorage.setItem('lang', 'ar');
    location.reload();
    this.lang='ar'
  }
  switchtoEn() {
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
    location.reload();
  }
}
