import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpertiseService } from './service/expertise.service';
import { Headers } from './data/data';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.css'],
})
export class ExpertiseComponent implements OnInit {
  constructor(
    private _expertiseApiService: ExpertiseService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  listHeaders: Headers[];
  loader: boolean = true;
  selectedTab: number = 0;

  ngOnInit(): void {
    var urlParams = new URLSearchParams(window.location.search);
    let tab = urlParams.get('tab');
    this._expertiseApiService.getheaders().subscribe((data) => {
      this.loader = true;

      if (data.status.code == 200) {
        this.loader = false;
        this.listHeaders = data.data.expertise;
        this.selectedTab = parseInt(tab);
      }
    });
  }
}
