<app-header></app-header>
<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="watchlist-cont">
  <div class="row zeromargin">
    <div class="col-12 top120 center">
      <img class="headerstar" src="./assets/images/Group 5124.svg" alt="" />
      <span class="headertext center">{{ "MY WATCHLIST" | translate }}</span>
    </div>
  </div>
</div>

<div
  class="row zeromargin blackbg sidepadding watch_top"
  dir="{{ 'dir' | translate }}"
>
  <div>
    <span class="verticalmiddle Rfs14">
      {{ "YOU HAVE" | translate }} {{ listNumber }} 
      <span class="text21"> {{ "FUNDSS" | translate }}</span>
      {{ "IN YOUR WATCHLIST" | translate }}</span
    >
  </div>

  <div class="watch_right colflex textdirection">
    <div class="watch_right">
      <img class="" src="./assets/images/delete (2).svg" alt="" />
      <span class="mleft15 text3 removeAllFunds" (click)="removeAllFunds()"
        >{{ "Delete" | translate
        }}<span class="text4"> {{ "alll" | translate }}</span
        ></span
      >
    </div>

    <button
      *ngIf="!allowDownload"
      disabled
      class="sharebutton sharebutton_disabled"
      type="button"
      name="button"
    >
      <img class="mright20" src="./assets/images/Group 5120.svg" alt="" />{{
        "Share funds doc." | translate
      }}
    </button>
    <button
      (click)="sendmail()"
      *ngIf="allowDownload"
      class="sharebutton"
      type="button"
      name="button"
    >
      <img class="mright20" src="./assets/images/Group 5120.svg" alt="" />{{
        "Share funds doc." | translate
      }}
    </button>
  </div>
</div>

<div class="row zeromargin" dir="{{ 'dir' | translate }}">
  <div class="col-12 areapadding">
    <div *ngIf="emptyState" class="empty_state">
      {{ "Your watch List Is Empty" | translate }}
    </div>

    <div *ngIf="!emptyState">
      <div class="row zeromargin search_holder hideinmedia">
        <div class="row zeromargin">
          <div class="checkbox_holder">
            <div>
              <span>
                <img
                  *ngIf="grid"
                  class="right10"
                  src="./assets/images/blueGrid.svg"
                  alt=""
                />

                <img
                  *ngIf="!grid"
                  class="right10"
                  src="./assets/images/Group 4928.svg"
                  alt=""
                /><span *ngIf="grid" style="color: #0092ff">{{
                  "Grid" | translate
                }}</span>
                <span *ngIf="!grid">{{ "Grid" | translate }}</span></span
              >
            </div>
            <div class="check_holder">
              <input
                type="checkbox"
                unchecked
                (change)="toGrid($event)"
                hidden="hidden"
                id="username"
              />
              <label class="switch" for="username"></label>
            </div>
            <div class="nav-item">
              <span>
                <img
                  *ngIf="grid"
                  class="right10"
                  src="./assets/images/listGray.svg"
                  alt=""
                />
                <img
                  *ngIf="!grid"
                  class="right10"
                  src="./assets/images/Group 5455.svg"
                  alt=""
                />
                <span *ngIf="!grid" style="color: #0092ff">
                  {{ "List" | translate }}
                </span>
                <span *ngIf="grid">
                  {{ "List" | translate }}
                </span></span
              >
            </div>
          </div>
        </div>

      <div *ngIf="grid == false">
        <nz-checkbox-wrapper style="width: 100%" (nzOnChange)="log($event)">
          <div class="row zeromargin graybg mtop20">
            <div class="col-12 fundspadding">
              <div class="row zeromargin rowalign">
                <div class="col-1"></div>
                <div class="col-5 zeropadding">
                  <div class="row zeromargin">
                    <div class="col-4 top7">
                      <p class="white11B center">
                        {{ "Fund name" | translate }}
                      </p>
                    </div>
                    <div class="col-2">
                      <p class="white11B zeromargin center">
                        {{ "Currency" | translate }}
                      </p>
                    </div>
                    <div class="col-3">
                      <p class="white11B zeromargin center">
                        {{ "Type" | translate }}
                      </p>
                    </div>
                    <div class="col-3 top7">
                      <p class="white11B center">{{ "RIC" | translate }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-5 zeropadding">
                  <div class="row zeromargin rowalign">
                    <div class="col-3 top7">
                      <p class="white11B center">
                        {{ "BBG Ticker" | translate }}
                      </p>
                    </div>
                    <div class="col-3 top7">
                      <p class="white11B center">
                        {{ "Subscription" | translate }}
                      </p>
                    </div>
                    <div class="col-3 top7">
                      <p class="white11B center">
                        {{ "Redemption" | translate }}
                      </p>
                    </div>
                    <div class="col-3 top7">
                      <p class="white11B center">{{ "NAV" | translate }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-1"></div>
              </div>

              <div class="row zeromargin top5">
                <div class="col-12 zeropadding">
                  <div class="borderbottom"></div>
                </div>
              </div>
              <div *ngFor="let fund of listfunds; let i = index">
                <div class="row zeromargin top5 rowalign">
                  <div class="col-1">
                    <!-- <img
                      class="leftfloat arrowrotate"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    /> -->
                    <img
                      class="rightfloat star"
                      src="./assets/images/Group 5124.svg"
                      alt=""
                      (click)="removeFromWatchList(fund.slug)"
                    />
                    <label nz-checkbox nzValue="{{ fund.id }}"> </label>
                  </div>
                  <div class="col-5 zeropadding">
                    <div class="row zeromargin rowalign">
                      <div class="col-4">
                        <p class="white14B center">{{ fund.name }}</p>
                      </div>
                      <div class="col-2">
                        <p class="white14B zeromargin center">
                          {{ fund.currency.symbol }}
                        </p>
                      </div>
                      <div class="col-3">
                        <p class="white14B zeromargin center">
                          {{ fund.asset.name }}
                        </p>
                      </div>
                      <div class="col-3">
                        <p class="white14B center">{{ fund.RIC }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-5 zeropadding">
                    <div class="row zeromargin rowalign">
                      <div class="col-3">
                        <p class="white14B center nowrap">{{ fund.bbg }}</p>
                      </div>
                      <div class="col-3">
                        <p class="white14B center">{{ fund.subscription }}</p>
                      </div>
                      <div class="col-3">
                        <p class="white14B center">{{ fund.redemption }}</p>
                      </div>
                      <div class="col-3">
                        <p class="white14B center">
                          {{ fund.currency.symbol }}
                          {{
                            fund?.last_nav == null ? " -" : fund?.last_nav.nav
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 zeropadding">
                    <button
                      class="showbuttonstyle"
                      type="button"
                      name="button"
                      (click)="gotoDynamic(fund.slug)"
                    >
                      {{ "Show Details" | translate }}
                    </button>
                  </div>
                </div>

                <div class="row zeromargin top5">
                  <div class="col-12 zeropadding">
                    <div class="borderbottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div *ngIf="grid == true">
        <nz-checkbox-wrapper style="width: 100%" (nzOnChange)="log($event)">
          <div class="row zeromargin mtop35">
            <div class="col-12 zeropadding">
              <div class="row zeromargin top20">
                <div
                  class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop10 textdirection"
                  *ngFor="let fund of listfunds; let i = index"
                >
                  <div class="lightgraybg gridboxpadding">
                    <div class="row zeromargin">
                      <div class="col-9">
                        <label nz-checkbox nzValue="{{ fund.id }}"> </label>
                        <span class="white13B"> {{ fund.name }}</span>
                      </div>
                      <div class="col-3">
                        <p class="blue13SB">{{ fund.currency.symbol }}</p>
                      </div>
                    </div>
                    <div class="row zeromargin">
                      <div class="col-12 top50 flex">
                        <span class="text1"
                          >{{ "Type" | translate }} :</span
                        >
                        <span class="text2"> {{ fund.asset.name }}</span>
                      </div>
                    </div>
                    <div class="row zeromargin">
                      <div class="col-12 top10 flex">
                        <span class="text1">{{ "RIC" | translate }} :</span>
                        <span class="text2"> {{ fund.RIC }}</span>
                      </div>
                    </div>

                    <div class="row zeromargin">
                      <div class="col-12 top10 flex">
                        <span class="text1"
                          >{{ "BBG Ticker" | translate }} :</span
                        >
                        <span class="text2"> {{ fund.bbg }}</span>
                      </div>
                    </div>
                    <div class="row zeromargin">
                      <div class="col-12 top10 flex">
                        <span class="text1"
                          >{{ "Redemption" | translate }} :</span
                        >
                        <span class="text2"> {{ fund.redemption }}</span>
                      </div>
                    </div>
                    <div class="row zeromargin">
                      <div class="col-12 top10 flex">
                        <span class="text1"
                          >{{ "Subscription" | translate }} :</span
                        >
                        <span class="text2"> {{ fund.subscription }}</span>
                      </div>
                    </div>
                    <div class="row zeromargin">
                      <div class="col-12 top10 flex">
                        <span class="text1">{{ "NAV" | translate }} :</span>
                        <span class="text2">
                          {{ fund.currency.symbol }}
                          {{
                            fund?.last_nav == null ? " -" : fund?.last_nav.nav
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="row zeromargin top23">
                      <div class="col-2 colalign">
                        <img
                          class="leftfloat star"
                          src="./assets/images/Group 5124.svg"
                          (click)="removeFromWatchList(fund.slug)"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <button
                          class="showbuttonstyle"
                          type="button"
                          (click)="gotoDynamic(fund.slug)"
                          name="button"
                        >
                          {{ "Show Details" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>

    <div class="showinmedia">
      <nz-checkbox-wrapper style="width: 100%" (nzOnChange)="log($event)">
        <div class="row zeromargin mtop35">
          <div class="col-12 zeropadding">
            <div class="row zeromargin top20">
              <div
                class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop10 textdirection"
                *ngFor="let fund of listfunds; let i = index"
              >
                <div class="lightgraybg gridboxpadding">
                  <div class="row zeromargin">
                    <div class="col-9">
                      <label nz-checkbox nzValue="{{ fund.id }}"> </label>
                      <span class="white13B"> {{ fund.name }}</span>
                    </div>
                    <div class="col-3">
                      <p class="blue13SB">{{ fund.currency.symbol }}</p>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top50 flex">
                      <span class="text1"
                        >{{ "Type" | translate }} :</span
                      >
                      <span class="text2"> {{ fund.asset.name }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1">{{ "RIC" | translate }} :</span>
                      <span class="text2"> {{ fund.RIC }}</span>
                    </div>
                  </div>

                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1"
                        >{{ "BBG Ticker" | translate }} :</span
                      >
                      <span class="text2"> {{ fund.bbg }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1"
                        >{{ "Redemption" | translate }} :</span
                      >
                      <span class="text2"> {{ fund.redemption }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1"
                        >{{ "Subscription" | translate }} :</span
                      >
                      <span class="text2"> {{ fund.subscription }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1">{{ "NAV" | translate }} :</span>
                      <span class="text2">
                        {{ fund.currency.symbol }}
                        {{
                          fund?.last_nav == null ? " -" : fund?.last_nav.nav
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="row zeromargin top23">
                    <div class="col-2 colalign">
                      <img
                        class="leftfloat star"
                        src="./assets/images/Group 5124.svg"
                        (click)="removeFromWatchList(fund.slug)"
                        alt=""
                      />
                    </div>
                    <div class="col-10">
                      <button
                        class="showbuttonstyle"
                        type="button"
                        (click)="gotoDynamic(fund.slug)"
                        name="button"
                      >
                        {{ "Show Details" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>
  </div>

  </div>

  <button
    type="button"
    id="openmodal"
    data-toggle="modal"
    data-target="#myModal"
    style="display: none"
  >
    Open Large Modal
  </button>
  <div
    class="modal fade textdirection"
    id="myModal"
    role="dialog"
    dir="{{ 'dir' | translate }}"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <p class="center modalheader">
            {{ "SHARE FUNDS DOCUMENT" | translate }}
          </p>
        </div>
        <!-- <p class="center modalsecondheader">YOU HAVE 1 DOCUMENTS SELECTED</p> -->
        <div class="modal-body send-mail-model">
          <div class="row zeromargin">
            <div
              nz-col
              *ngFor="let item of selectedFiles"
              class="row"
              style="width: 100%"
            >
              <div
                class="blackbox col-lg-6 col-md-12 col-sm-12 col-12 zeroleft Rtop10 Rpadding0 Mtop15"
                *ngFor="let selectedItem of item"
              >
                <div class="checkbox_holder">
                  <div class="details_holder">
                    <div>
                      <span class="SB11">{{ selectedItem.original_name }}</span>
                      <span class="rightfloat SB9">PDF</span>
                    </div>

                    <div>
                      <span class="SB11">EN</span>
                      <span class="rightfloat SB9">{{
                        selectedItem.size
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="sendtextmodal center">
          {{ "Send document by email" | translate }}
        </p>
        <div class="row zeromargin">
          <div class="col-12">
            <input
              class="modalinput"
              type="text"
              name=""
              id=""
              [(ngModel)]="emailName"
              placeholder="{{ 'Type an Email' | translate }}"
            />
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12 center">
            <button class="modalbutton" (click)="submitMail()">
              {{ "Send Documents" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
