import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomepageService } from './service/homepage.service';
import { Funds } from './data/data';
import { DomSanitizer } from '@angular/platform-browser';
import { videos } from './data/data';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {
  dataSource: Object;
  chartConfig: Object;
  constructor(
    private _fundsApiService: HomepageService,
    private _reportApiService: HomepageService,
    private sanitizer: DomSanitizer,

    private route: ActivatedRoute,
    private router: Router
  ) {
    this.chartConfig = {
      width: '700',
      height: '400',
      type: 'column2d',
      dataFormat: 'json',
    };

    this.dataSource = {
      chart: {
        caption: 'Countries With Most Oil Reserves [2017-18]',
        subCaption: 'In MMbbl = One Million barrels',
        xAxisName: 'Country',
        yAxisName: 'Reserves (MMbbl)',
        numberSuffix: 'K',
        theme: 'fusion',
      },
      data: [
        {
          label: 'Venezuela',
          value: '290',
        },
        {
          label: 'Saudi',
          value: '260',
        },
        {
          label: 'Canada',
          value: '180',
        },
        {
          label: 'Iran',
          value: '140',
        },
        {
          label: 'Russia',
          value: '115',
        },
        {
          label: 'UAE',
          value: '100',
        },
        {
          label: 'US',
          value: '30',
        },
        {
          label: 'China',
          value: '30',
        },
      ],
    };
  }

  listVideos: videos[];

  loader: boolean = true;

  listfunds: Funds[];

  gotoDynamic(fund) {
    this.router.navigate(['/fundpage', { name: fund }]);
  }
  navigateTocairo(){
    this.router.navigateByUrl('/globalnetwork');
  }
  navigateToEurope(){
    this.router.navigateByUrl('/globalnetwork?tab=0');
  }
  navigateToAmirica(){
    this.router.navigateByUrl('/globalnetwork?tab=1');
  }
  navigateToAsia(){
    this.router.navigateByUrl('/globalnetwork?tab=2');
  }
  navigateToMenat(){
    this.router.navigateByUrl('/globalnetwork?tab=3');
  }
  ngOnInit(): void {

    window.scrollTo(0,0);

    this._fundsApiService.getfunds().subscribe((data) => {
      this.loader = true;
      if (data.status.code == 200) {
        this.loader = false;
        this.listfunds = data.data;
      }
    });

    this._reportApiService.getVideos().subscribe((data) => {
      if (data.status.code == 200) {
        this.listVideos = data.data;
        this.listVideos.map((objvideo) => {
          // console.log(objvideo);
          objvideo.link = this.sanitizer.bypassSecurityTrustResourceUrl(
            objvideo.link
          );
          return objvideo;
        });
      }
    });
    
  }


}
