import { Component, OnInit } from '@angular/core';
import { AssetsService } from './service/assets.service';
import { Assets } from "./data/data";

@Component({
  selector: 'app-assetclass',
  templateUrl: './assetclass.component.html',
  styleUrls: ['./assetclass.component.css']
})
export class AssetclassComponent implements OnInit {

  constructor(private _assetsApiService: AssetsService) { }

  listAssets: Assets[];


  ngOnInit(): void {

    this._assetsApiService.getassets().subscribe((data) => {
      if (data.status.code == 200) {
        this.listAssets = data.data;
      }
    });
  }

}
