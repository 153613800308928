<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="careers-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">{{ "Privacy" | translate }}</p>
    </div>
  </div>
</div>

<div class="row zeromargin blackbg">
  <div class="col-12 sidepadding">
      <div class="row zeromargin top20 bot30">
        <div class="col-12 textdirection">
          <div class=" term" [innerHtml]="listprivacy"></div>
        </div>
      </div>
  </div>
</div>

<app-footer></app-footer>
