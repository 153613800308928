<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="expertise-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">
        {{ "INSIGHTS AND DOCUMENTS" | translate }}
      </p>
      <p class="subheadertext center">
        {{ "Related documents and media coverage" | translate }}
      </p>
    </div>
  </div>
</div>

<nz-tabset
  [nzSelectedIndex]="selectedTab"
  class="textdirection sliderdirection"
>
  <nz-tab nzTitle="{{ 'AWARDS' | translate }}" (nzClick)="hello()">
    <div class="Awardssection">
      <mdb-carousel
        [isControls]="true"
        class="carousel-multi-item multi-animation"
        [type]="'carousel-multi-item'"
        [animation]="'slide'"
        [interval]="false"
      >
        <mdb-carousel-item *ngFor="let item of awardsSlides; let i = index">
          <div class="row">
            <div
              *ngFor="let card of item; let cardIndex = index"
              class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop15"
            >
              <div class="row zeromargin">
                <div class="col-12 zeropadding whitebg">
                  <div class="imgborder">
                  <img class="awardimg" src="{{ card.img }}" alt="" />
                </div>
                </div>
              </div>
              <div class="row zeromargin awardbox">
                <div class="col-12">
                  <p class="awardsfont top8">{{ card.title }}</p>
                  <p class="electionfont">{{ card.desc }}</p>
                  <p class="text1 awardtextoverflow">
                    {{ card.content }}
                  </p>
                  <p class="datefont top25">{{ card.date }}</p>
                </div>
              </div>
            </div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </nz-tab>

  <nz-tab
    nzTitle="{{ 'Code of Ethics' | translate }} {{ '&' | translate }} {{
      'CSR' | translate
    }} "
    (nzClick)="hello()"
  >
    <!-- <div class="row zeromargin">
      <div class="col-12">
        <span class="blue19">{{ "About" | translate }} </span
        ><span class="white19BL">{{listHeaders?.corporate.name}}</span>
      </div>
    </div> -->
    <div class="row zeromargin">
      <div class="col-12">
        <div
          class="corporate"
          [innerHtml]="listHeaders?.corporate.content"
        ></div>
      </div>
    </div>
  </nz-tab>

  <!-- <nz-tab nzTitle="{{ 'Code of Ethics' | translate }}">
    <div class="row zeromargin">
      <div class="col-12">
        <span class="blue19">{{ "About" | translate }} </span
        ><span class="white19BL">{{listHeaders?.ethics.name}}</span>
      </div>
    </div>
    <div class="row zeromargin">
      <div class="col-12">
        <div class="corporate" [innerHtml]="listHeaders?.ethics.content">

        </div>
      </div>
    </div>
  </nz-tab> -->

  <nz-tab nzTitle="{{ 'REPORTS' | translate }}" (nzClick)="hello()">
    <div class="reportsection">
      <mdb-carousel
        [isControls]="true"
        class="carousel-multi-item multi-animation"
        [type]="'carousel-multi-item'"
        [animation]="'slide'"
        [interval]="false"
      >
        <mdb-carousel-item *ngFor="let item of slides; let i = index">
          <div class="row">
            <div
              *ngFor="let card of item; let cardIndex = index"
              class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop15"
            >
              <div class="reportbox">
                <div class="row zeromargin">
                  <div class="col-12">
                    <img
                      class="imgrotate"
                      src="./assets/images/Group 4943.svg"
                      alt=""
                    />
                    <span class="blue9BL left12">{{
                      "REPORTS" | translate
                    }}</span>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top18">
                    <p class="white16SB">{{ card.title }}</p>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top18">
                    <p class="white10 textoverflow">{{card.description}}
                    </p>
                  </div>
                </div>
                <div class="row zeromargin top25">
                  <div class="col-6">
                    <div class="relative">
                      <p class="white10SB">{{ card.date }}</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="relative">
                      <a
                        href="{{ card.document }}"
                        download
                        target="_blank"
                        class="downloadfont"
                        >{{ "Download PDF" | translate }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </nz-tab>

  <nz-tab nzTitle="{{ 'MEDIA' | translate }}" (nzClick)="hello()">
    <div class="row zeromargin">
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 Rtop15 top20"
        *ngFor="let card of listVideos"
      >
        <iframe
          class="videosize"
          [src]="card.link"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </nz-tab>

  <nz-tab nzTitle="{{ 'NEWS' | translate }}" (nzClick)="hello()">
    <div class="newssection">
      <mdb-carousel
        [isControls]="true"
        class="carousel-multi-item multi-animation"
        [type]="'carousel-multi-item'"
        [animation]="'slide'"
        [interval]="false"
      >
        <mdb-carousel-item *ngFor="let item of newsSlides; let i = index">
          <div class="row zeromargin fullwidth">
            <div
              *ngFor="let card of item; let cardIndex = index"
              class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop15"
            >
              <div class="newsbox">
                <div class="row zeromargin">
                  <div class="col-12">
                    <img
                      class="imgrotate"
                      src="./assets/images/Group 4943.svg"
                      alt=""
                    />
                    <span class="blue9BL left12">{{ 'NEWS' | translate }}</span>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top18">
                    <p class="white16SB">{{ card.title }}</p>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top18">
                    <p class="white10 textoverflow">{{card.name}}
                    </p>
                  </div>
                </div>

                <!-- <div class="row zeromargin">
                  <div class="col-12 top18">
                    <p class="white16SB">{{ card.name }}</p>
                  </div>
                </div> -->
                <div class="row zeromargin top25">
                  <div class="col-6">
                    <div class="relative">
                      <p class="white10SB">{{ card.date }}</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="relative">
                      <a
                        *ngIf="card.is_external == 1"
                        href="{{ card.document }}"
                        download
                        target="_blank"
                        ><p class="downloadfont">
                          {{ "Read More" | translate }}
                        </p></a
                      >
                      <a
                        *ngIf="card.is_external == 0"
                        href="{{ card.document }}"
                        download
                        target="_blank"
                        ><p class="downloadfont">
                          {{ "Download PDF" | translate }}
                        </p></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </nz-tab>
</nz-tabset>

<app-footer></app-footer>
