import { Component, OnInit } from '@angular/core';
import { GlobalenetworkService } from './service/globalenetwork.service';
import { Coninent } from "./data/data";
import {​​​​​​​​ Router,ActivatedRoute }​​​​​​​​ from'@angular/router';

@Component({
  selector: 'app-globalnetwork',
  templateUrl: './globalnetwork.component.html',
  styleUrls: ['./globalnetwork.component.css']
})
export class GlobalnetworkComponent implements OnInit {

  constructor(private _coninentApiService: GlobalenetworkService,private route: ActivatedRoute,private router : Router) { }
 
  listconinent: Coninent[];
  selectedTab: number = 0;
  loader: boolean = true;


  ngOnInit(): void {
    window.scrollTo(0,0);

    var urlParams = new URLSearchParams(window.location.search);
    let tab = urlParams.get('tab');
    this._coninentApiService.getcontinent().subscribe((data) => {
      this.loader = true;

      if (data.status.code == 200) {
        this.loader = false;

        this.listconinent = data.data;
        this.selectedTab = parseInt(tab);

      }
      // console.log(data.data)
    });
  }

}
