import { Component, OnInit } from '@angular/core';
import { FundsService } from './service/funds.service';
import { ToastrService } from 'ngx-toastr';
import { Funds } from './data/data';
import { Router, ActivatedRoute } from '@angular/router';
import { TestBed } from '@angular/core/testing';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.css'],
})
export class WatchlistComponent implements OnInit {
  constructor(
    private _fundsApiService: FundsService,
    public toastr: ToastrService,
    private router: Router
  ) {}
  loader: boolean = true;
  listfunds: Funds[];
  grid = false;
  listNumber: number;
  emptyState = false;
  selectedFiles = [];
  allowDownload = false;
  public emailName: any = '';
  filesLinks = [];

  toGrid = (e: any) => {
    this.grid = !this.grid;
  };

  gotoDynamic(fund: any) {
    this.router.navigate(['/fundpage', { name: fund }]);
  }

  sendmail() {
    document.getElementById('openmodal').click();
  }

  submitMail() {
    this.loader = true;
    var formData = new FormData();
    var index = 0;

    formData.append('email', this.emailName);
    this.filesLinks.forEach(function (item) {
      formData.append('pdf[' + index + ']', item);
      index++;
    });

    this._fundsApiService.submitEmail(formData).subscribe((data) => {
      if (data['status'].code == '200') {
        this.toastr.success(data['status'].message);
        this.loader = false;
      } else {
        this.toastr.error(data['status'].message);
        this.loader = false;
      }
    });
  }

  log(value): void {
    if (value.length != 0) {
      this.allowDownload = true;
    } else {
      this.allowDownload = false;
    }
    let filesPath = [];
    let selctedFilter = [];
    this.listfunds.map((element) => {
      value.map((element1) => {
        if (element.id == element1) {
          element.files.map((file) => {
            filesPath.push(file.download_link);
          });

          selctedFilter.push(element.files);
        }
      });
    });

    this.selectedFiles = selctedFilter;
    this.filesLinks = filesPath;
  }

  getWatchList() {
    let itemsList = JSON.parse(localStorage.getItem('list'));
    var body = new FormData();
    var index = 0;

    if (itemsList.length == 0) {
      this.listfunds = [];
      this.emptyState = true;
      this.loader = false;
    } else {
      itemsList.forEach(function (item) {
        body.append('fund_ids[' + index + ']', item);
        index++;
      });

      this._fundsApiService.filterFundCalling(body).subscribe((data) => {
        if (data['status'].code == '200') {
          this.loader = false;
          this.listfunds = data.data;
          if (data.data.length == 0) {
            this.emptyState = true;
          }
        } else {
          this.toastr.error(data['status'].message);
          this.loader = false;
          this.emptyState = false;
        }
      });
    }
  }

  removeFromWatchList = (e: any) => {
    let watchList = JSON.parse(localStorage.getItem('list'));
    let filterdWatchList = watchList.filter((item) => item !== e);
    localStorage.setItem('list', JSON.stringify(filterdWatchList));
    setTimeout(() => {
      location.reload();
    }, 500);
  };

  removeAllFunds() {
    let a = [];
    localStorage.setItem('list', JSON.stringify(a));
    location.reload();
  }

  ngOnInit(): void {
    this.listNumber = JSON.parse(localStorage.getItem('list')).length;
    this.getWatchList();
  }
}
