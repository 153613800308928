import { Component, OnInit } from '@angular/core';
import { FundsService } from './service/funds.service';
import { Funds } from './data/data';
import { Router, ActivatedRoute } from '@angular/router';
import { WavesModule, ButtonsModule } from 'angular-bootstrap-md';
import { Options } from 'ng5-slider';
import { ToastrService } from 'ngx-toastr';
import { isPlatformWorkerApp } from '@angular/common';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.css'],
})
export class FundsComponent implements OnInit {
  public applyFilter: any = {
    assetName: 'all',
    fundOption: 'fund_name',
    fundText: '',
  };

  constructor(
    private _fundsApiService: FundsService,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService
  ) {}

  loader: boolean = true;
  switchValue = false;
  loading = false;
  grid = false;
  value: number = 0;
  highValue: number = 0;
  minRange = 0;
  maxRange = 100;
  tooltipTitle: any;
  tooltipTitleName: any;
  options: Options = this.getOptions();
  showRange: boolean = false;

  getOptions(): Options {
    return {
      floor: this.minRange,
      ceil: this.maxRange,
      // minRange: this.minRange,
      // maxRange: this.maxRange,
    };
  }

  toGrid = (e: any) => {
    this.grid = !this.grid;
  };

  listfunds: Funds[];
  assets: any;
  currencies: any;
  checked = false;
  currency_id = '';
  description: '';
  emptyState = false;
  gotoDynamic(fund: any) {
    this.router.navigate(['/fundpage', { name: fund }]);
  }

  selectFundEquity() {
    let e = this.applyFilter.assetName;
    let selctedAssets = this.assets.filter((item: { id: any }) => item.id == e);
    if (selctedAssets.length != 0) {
      this.tooltipTitle = selctedAssets[0].description;
      this.tooltipTitleName = selctedAssets[0].name;
    }else{
      this.tooltipTitle = " ";
      this.tooltipTitleName = "FUNDS";
    }
    this.applyFilterMethod();
  }

  addToWatchList = (e: any) => {
    let watchList = JSON.parse(localStorage.getItem('list'));
    if (!watchList.includes(e)) {
      watchList.push(e);
      localStorage.setItem('list', JSON.stringify(watchList));
    } else {
      let filterdWatchList = watchList.filter((item) => item !== e);
      localStorage.setItem('list', JSON.stringify(filterdWatchList));
    }
    setTimeout(() => {
      location.reload();
    }, 500);
  };

  getSelctedCurrency(e) {
    this.showRange = true;
    let selctedCurrency = this.currencies.filter(
      (item: { id: any }) => item.id == e
    );
    this.minRange = selctedCurrency[0].low_range;
    this.maxRange = selctedCurrency[0].high_range;
    this.value = selctedCurrency[0].low_range;
    this.highValue = selctedCurrency[0].high_range;
    this.options = this.getOptions();
  }

  applyFilterMethod = () => {
    this.loader = true;
    let body = {
      asset_id: this.applyFilter.assetName,
      high_nav: this.highValue,
      low_nav: this.value,
      currency_id: this.currency_id,
      fund_name:
        this.applyFilter.fundOption == 'fund_name'
          ? this.applyFilter.fundText
          : '',
      ric:
        this.applyFilter.fundOption == 'ric' ? this.applyFilter.fundText : '',
      bbg:
        this.applyFilter.fundOption == 'bbg' ? this.applyFilter.fundText : '',
      subscription:
        this.applyFilter.fundOption == 'subscription'
          ? this.applyFilter.fundText
          : '',
      redemption:
        this.applyFilter.fundOption == 'redemption'
          ? this.applyFilter.fundText
          : '',
    };
    this._fundsApiService.filterFundCalling(body).subscribe((data) => {
      if (data.status.code == 200) {
        this.loader = false;
        let itemsList = JSON.parse(localStorage.getItem('list'));
        if (data.data.length == 0) {
          this.listfunds = data.data;
          this.emptyState = true;
        } else {
          this.emptyState = false;
          let selctedFilter = [];
          let allFunds = [];
          data.data.map((element) => {
            itemsList.map((element1) => {
              if (element.id == element1) {
                selctedFilter.push(element);
              }
            });
          });

          let unSelctedFilter = data.data.filter(
            (item1) => !selctedFilter.some((item2) => item2.id === item1.id)
          );

          let addedflag = selctedFilter.map((item) => ({
            id: item.id,
            logo: item.logo,
            name: item.name,
            slug: item.slug,
            objective: item.objective,
            redemption: item.redemption,
            subscription: item.subscription,
            selected: true,
            RIC: item.RIC,
            asset: {
              description: item.asset.description,
              id: item.asset.id,
              name: item.asset.name,
            },
            asset_id: item.asset_id,
            bbg: item.bbg,
            currency: {
              id: item.currency.id,
              name: item.currency.name,
              symbol: item.currency.symbol,
            },
            currency_id: item.currency_id,
            date: item.date,
            last_nav: {
              id: item.last_nav == null ? '--' : item.last_nav.id,
              fund_id: item.last_nav == null ? '--' : item.last_nav.fund_id,
              nav: item.last_nav == null ? '--' : item.last_nav.nav,
              date: item.last_nav == null ? '--' : item.last_nav.date,
            },
          }));

          let addedUnflag = unSelctedFilter.map((item) => ({
            id: item.id,
            logo: item.logo,
            name: item.name,
            slug: item.slug,
            objective: item.objective,
            redemption: item.redemption,
            subscription: item.subscription,
            selected: false,
            RIC: item.RIC,
            asset: {
              description: item.asset.description,
              id: item.asset.id,
              name: item.asset.name,
            },
            asset_id: item.asset_id,
            bbg: item.bbg,
            currency: {
              id: item.currency.id,
              name: item.currency.name,
              symbol: item.currency.symbol,
            },
            currency_id: item.currency_id,
            date: item.date,
            last_nav: {
              id: item.last_nav == null ? '--' : item.last_nav.id,
              fund_id: item.last_nav == null ? '--' : item.last_nav.fund_id,
              nav: item.last_nav == null ? '--' : item.last_nav.nav,
              date: item.last_nav == null ? '--' : item.last_nav.date,
            },
          }));

          allFunds.push(...addedflag);
          allFunds.push(...addedUnflag);
          this.listfunds = allFunds;
        }
      }
      // if (data['status'].code == '200') {
      //   this.listfunds = data.data;
      //   this.loader = false;
      // } else {
      //   this.toastr.error(data['status'].message);
      //   this.loader = false;
      // }
    });
  };

  reload = () => {
    location.reload();
  };
  clearAllFilters = () => {
    (this.applyFilter.assetName = ''),
      (this.applyFilter.fundText = ''),
      (this.applyFilter.fundOption = 'fund_name'),
      (this.tooltipTitleName = 'FUNDS'),
      (this.loader = true);
    let body = {
      asset_id: '',
      high_nav: 0,
      low_nav: 0,
      currency_id: '',
      fund_name: '',
      ric: '',
      bbg: '',
      subscription: '',
      redemption: '',
    };
    this._fundsApiService.filterFundCalling(body).subscribe((data) => {
      if (data.status.code == 200) {
        this.loader = false;
        let itemsList = JSON.parse(localStorage.getItem('list'));
        if (data.data.length == 0) {
          this.listfunds = data.data;
          this.emptyState = true;
        } else {
          this.emptyState = false;
          let selctedFilter = [];
          let allFunds = [];
          data.data.map((element) => {
            itemsList.map((element1) => {
              if (element.id == element1) {
                selctedFilter.push(element);
              }
            });
          });

          let unSelctedFilter = data.data.filter(
            (item1) => !selctedFilter.some((item2) => item2.id === item1.id)
          );

          let addedflag = selctedFilter.map((item) => ({
            id: item.id,
            logo: item.logo,
            name: item.name,
            slug: item.slug,
            objective: item.objective,
            redemption: item.redemption,
            subscription: item.subscription,
            selected: true,
            RIC: item.RIC,
            asset: {
              description: item.asset.description,
              id: item.asset.id,
              name: item.asset.name,
            },
            asset_id: item.asset_id,
            bbg: item.bbg,
            currency: {
              id: item.currency.id,
              name: item.currency.name,
              symbol: item.currency.symbol,
            },
            currency_id: item.currency_id,
            date: item.date,
            last_nav: {
              id: item.last_nav == null ? '--' : item.last_nav.id,
              fund_id: item.last_nav == null ? '--' : item.last_nav.fund_id,
              nav: item.last_nav == null ? '--' : item.last_nav.nav,
              date: item.last_nav == null ? '--' : item.last_nav.date,
            },
          }));

          let addedUnflag = unSelctedFilter.map((item) => ({
            id: item.id,
            logo: item.logo,
            name: item.name,
            slug: item.slug,
            objective: item.objective,
            redemption: item.redemption,
            subscription: item.subscription,
            selected: false,
            RIC: item.RIC,
            asset: {
              description: item.asset.description,
              id: item.asset.id,
              name: item.asset.name,
            },
            asset_id: item.asset_id,
            bbg: item.bbg,
            currency: {
              id: item.currency.id,
              name: item.currency.name,
              symbol: item.currency.symbol,
            },
            currency_id: item.currency_id,
            date: item.date,
            last_nav: {
              id: item.last_nav == null ? '--' : item.last_nav.id,
              fund_id: item.last_nav == null ? '--' : item.last_nav.fund_id,
              nav: item.last_nav == null ? '--' : item.last_nav.nav,
              date: item.last_nav == null ? '--' : item.last_nav.date,
            },
          }));

          allFunds.push(...addedflag);
          allFunds.push(...addedUnflag);
          this.listfunds = allFunds;
        }
      } else {
        this.toastr.error(data['status'].message);
        this.loader = false;
      }
    });
  };

  ngOnInit(): void {
    let lang = localStorage.getItem('lang');
    if (lang == 'en') {
      this.tooltipTitleName = 'FUNDS';
    } else {
      this.tooltipTitleName = 'الصندوق';
    }
    let fundText = this.route.snapshot.paramMap.get('fundText');
    let fundOption = this.route.snapshot.paramMap.get('fundOption');

    if (fundOption != undefined) {
      this.applyFilter.fundText = fundText;
      this.applyFilter.fundOption = fundOption;
      this.applyFilterMethod();
    } else {
      this._fundsApiService.getfunds().subscribe((data) => {
        this.loader = true;

        if (data.status.code == 200) {
          this.loader = false;
          let itemsList = JSON.parse(localStorage.getItem('list'));
          if (data.data.length == 0) {
            this.listfunds = data.data;
            this.emptyState = true;
          } else {
            this.emptyState = false;
            let selctedFilter = [];
            let allFunds = [];
            data.data.map((element) => {
              itemsList.map((element1) => {
                if (element.id == element1) {
                  selctedFilter.push(element);
                }
              });
            });

            let unSelctedFilter = data.data.filter(
              (item1) => !selctedFilter.some((item2) => item2.id === item1.id)
            );

            let addedflag = selctedFilter.map((item) => ({
              id: item.id,
              logo: item.logo,
              name: item.name,
              slug: item.slug,
              objective: item.objective,
              redemption: item.redemption,
              subscription: item.subscription,
              selected: true,
              RIC: item.RIC,
              asset: {
                description: item.asset.description,
                id: item.asset.id,
                name: item.asset.name,
              },
              asset_id: item.asset_id,
              bbg: item.bbg,
              currency: {
                id: item.currency.id,
                name: item.currency.name,
                symbol: item.currency.symbol,
              },
              currency_id: item.currency_id,
              date: item.date,
              last_nav: {
                id: item.last_nav == null ? '--' : item.last_nav.id,
                fund_id: item.last_nav == null ? '--' : item.last_nav.fund_id,
                nav: item.last_nav == null ? '--' : item.last_nav.nav,
                date: item.last_nav == null ? '--' : item.last_nav.date,
              },
            }));

            let addedUnflag = unSelctedFilter.map((item) => ({
              id: item.id,
              logo: item.logo,
              name: item.name,
              slug: item.slug,
              objective: item.objective,
              redemption: item.redemption,
              subscription: item.subscription,
              selected: false,
              RIC: item.RIC,
              asset: {
                description: item.asset.description,
                id: item.asset.id,
                name: item.asset.name,
              },
              asset_id: item.asset_id,
              bbg: item.bbg,
              currency: {
                id: item.currency.id,
                name: item.currency.name,
                symbol: item.currency.symbol,
              },
              currency_id: item.currency_id,
              date: item.date,
              last_nav: {
                id: item.last_nav == null ? '--' : item.last_nav.id,
                fund_id: item.last_nav == null ? '--' : item.last_nav.fund_id,
                nav: item.last_nav == null ? '--' : item.last_nav.nav,
                date: item.last_nav == null ? '--' : item.last_nav.date,
              },
            }));

            allFunds.push(...addedflag);
            allFunds.push(...addedUnflag);
            this.listfunds = allFunds;
          }
        } else {
          this.toastr.error(data['status'].message);
          this.loader = false;
        }
      });
    }

    this._fundsApiService.getFundFilter().subscribe((data) => {
      if (data.status.code == 200) {
        this.assets = data.data.assets;

        // console.log(data.data.assets[0].name);
        // this.applyFilter.assetName = data.data.assets[0].name;
        this.currencies = data.data.currencies;
      }
    });
  }
}
