<div class="row zeromargin">
  <div class="col-12 pagepadding fixed1">
    <a href=""><img class="logosize" src="./assets/images/logoblue.png" alt=""></a>
    <div class="rightfloat top20 hideinmedia">
      <a href="https://twitter.com/AzimutEgypt" target="_blank"><img class="smsize" src="./assets/images/Group 72 (1).svg" alt=""></a>
      <a href="https://www.facebook.com/AzimutEgypt" target="_blank"><img class="smsize" src="./assets/images/Group 74 (1).svg" alt=""></a>
      <a href="https://www.instagram.com/azimutegypt" target="_blank"><img class="smsize" src="./assets/images/Group 75 (1).svg" alt=""></a>
      <a href="https://www.linkedin.com/company/azimut-egypt" target="_blank"><img class="smsize" src="./assets/images/Group 80 (1).svg" alt=""></a>
    </div>


    <div class="rightfloat showinmedia x menubuttonplace">
      <button class="menubutton" onclick="myFunction()"> <img src="./assets/images/menu icon (2).svg" alt=""></button>
      <div id="myDIV" class="rightfloat topp20 y">
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://twitter.com/AzimutEgypt" target="_blank"><img class="smsize11" src="./assets/images/Group 72 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://www.facebook.com/AzimutEgypt" target="_blank"><img class="smsize1" src="./assets/images/Group 74 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://www.instagram.com/azimutegypt" target="_blank"><img class="smsize11" src="./assets/images/Group 75 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="row zeromargin">
          <div class="col-12  highindex zeropadding">
            <div class="circle">
              <a href="https://www.linkedin.com/company/azimut-egypt" target="_blank"><img class="smsize111" src="./assets/images/Group 80 (1).svg" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-cont">
  <div class="row zeromargin pagetitlepadding">
    <span class=" hometext">Home</span><span class="spadding">/</span><span class="pagename"> AZ- أدخــــار</span>
  </div>
</div>


<div class="main-cont">


<div class="row zeromargin page2padding">

  <div class="col-lg-4 col-md-12 col-sm-12 col-12 showinmedia">
    <img class="imagedimention" src="./assets/images/Mask Group 13@2x.png" alt="">
  </div>

  <div class="col-lg-8 col-md-12 col-sm-12 col-12 ">

    <div class="row zeromargin ">
      <div class="col-12  top30">
        <div class="">
          <span class="leftborder"></span>
          <p class="header">AZ- ادخــــــــار</p>
        </div>
      </div>
    </div>

    <div class="row zeromargin">
      <div class="col-12">
        <p class="parag">AZ-ادخار” is an open-ended private placement fund open for daily subscription and weekly redemption, investing in fixed income instruments.</p>
        <p class="parag"> “AZ-ادخار” is a high yielding saving/investing vehicle with low risk for high net worth and retail clients and also acts as a unique tool
           for cash management and liquidity purposes for corporates.</p>
        <p class="parag">“AZ-ادخار” is a derivative from proto-types Money Market and Fixed Income Funds
           allows easy movement along the yield curve, building a maturity ladder across the different fixed income instruments.</p>
      </div>
    </div>


    <div class="row zeromargin">
      <div class="col-12  top30">
        <div class="">
          <span class="leftborder"></span>
          <p class="header">More Details</p>
        </div>
      </div>
    </div>

    <div class="row zeromargin bottom15">
      <div class="col-lg-5 col-md-6 col-sm-12 col-12 ">
        <div class="">
          <a href="./assets/pdf/Digital_flyer (1).pdf"><img class="imgsize relative boxshadow" src="./assets/images/Mask Group 11@2x.png" alt="">
          <div class="textbox absolute">
            <span class="pdfname">AZ- ادخـــــار Digital Flyer. PFD</span>
            <a href="./assets/pdf/Digital_flyer (1).pdf" download><img class="rightfloat pdfimg" src="./assets/images/download-file.svg" alt=""></a>
          </div>
        </a>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12 col-12 rtop20 ">
        <div class="">
          <a  href="./assets/pdf/AZ-ادخار fund prospectus.pdf"><img class="imgsize relative boxshadow" src="./assets/images/Mask Group 12@2x.png" alt="">
          <div class="textbox absolute">
            <span class="pdfname">AZ- ادخـــــار Fund Prospe.. . PFD</span>
            <a href="./assets/pdf/AZ-ادخار fund prospectus.pdf" download><img class="rightfloat pdfimg" src="./assets/images/download-file.svg" alt=""></a>
          </div>
        </a>
        </div>
      </div>
    </div>

    <div class="row zeromargin">
      <div class="col-12  top30">
        <div class="">
          <span class="leftborder"></span>
          <p class="header">Pricing</p>
        </div>

        <div class="row zeromargin ">
          <div class="col-lg-10 col-md-12 col-sm12 col-12  bluecolor boxpadding">
            <div class="row zeromargin">
              <span class="subs">Subscription For Unit</span><img class="mleft10" src="./assets/images/Group 4757.svg" alt="">
            </div>
            <div class="row zeromargin top101">
              <div class="col-lg-4 col-md-12 col-sm-12 col-12 borderright zeropadding">
                <span class="text1">NAV :</span><span id="test" class="text20"></span>
                <br>
                <span class="text1">Date :</span><span id="test2" class="text10"></span>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12 col-12 left40">
                <span class="text1">Base currency :</span><span class="text20">EGP</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>

  <div class="col-lg-4 col-md-12 col-sm-12 col-12 hideinmedia">
    <img class="imagedimention" src="./assets/images/Mask Group 13@2x.png" alt="">
  </div>

</div>
</div>

<div class="row zeromargin darkblue">
  <div class="col-12 ">


<div class="row zeromargin footerpadding">

    <div class="col-lg-2 col-md-12 col-sm-12 col-12">
      <a href=""><img class="logo2size" src="./assets/images/logo.png" alt=""></a>
    </div>
    <div class="col-lg-3 col-md-10 col-sm-10 col-10 rtop20">
      <div class="row zeromargin">
        <div class="col-12">
          <img class="infosize" src="./assets/images/Path 85.svg" alt="">
          <span class="infostyle">Address</span>
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 top10">
          <p class="infodetails right70">Smart Village | Building B 16 | PO Box 12577 | Giza, Egypt</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
      <div class="row zeromargin">
        <div class="col-12">
          <img class="infosize" src="./assets/images/Path 67.svg" alt="">
          <span class="infostyle">Phone Number</span>
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 top10">
          <a href="tel:+20 235353600"><p class="infodetails">+20 235353600</p></a>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
      <div class="row zeromargin">
        <div class="col-12">
          <img class="infosize" src="./assets/images/Group 119.svg" alt="">
          <span class="infostyle">E-mail</span>
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 top10">
          <a href="mailto:i@azimut.eg"><p class="infodetails">i@azimut.eg</p></a>
        </div>
      </div>
    </div>

  </div>

  <div class="row zeromargin">
    <div class="col-12 sidepadding">
      <div class="borderbottom">
      </div>
    </div>
  </div>

  <div class="row zeromargin">
    <div class="col-12 center top15">
      <span class="footertext">Copyright Azimut © All Rights Reserved.</span>
    </div>
  </div>

  </div>
</div>
