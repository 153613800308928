<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="careers-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">{{ "AZIMUT CAREERS" | translate }}</p>
      <p class="subheadertext center">
        {{ "KNOW-HOW, EXPERIENCE AND UNCONDITIONAL VALUES" | translate }}
      </p>
    </div>
  </div>
</div>

<div class="row zeromargin">
  <div class="col-12 top40 center">
    <p class="blue35 center">{{ "WHY JOIN US" | translate }}</p>
    <div class="lightgray16 center careerstext textpadding" [innerHtml]="listHeaders?.careers.content">

    </div>
  </div>
</div>

<div class="row zeromargin side115 relative" dir="{{ 'dir' | translate }}">
  <div class="col-lg-4 col-md-12 col-sm-12 col-12 center">
    <div class="imgtoggle">
      <img
        class="padding38 firstimg"
        src="./assets/images/Icon (3).svg"
        alt=""
      />
      <img class="padding38 hoverimg" src="./assets/images/Icon11.svg" alt="" />
    </div>
    <p class="title mbot10 center">{{ "Fast Growing Company" | translate }}</p>
    <p class="gray17 center">
      {{ "Azimut Egypt is rapidly growing providing you everyday exposure to local, regional, and global markets." | translate }}
      
    </p>
  </div>
  <img class="absolute hideinmedia" src="./assets/images/Path 9.svg" alt="" />
  <div class="col-lg-4 col-md-12 col-sm-12 col-12 center">
    <div class="imgtoggle">
      <img
        class="padding38 hoverimg"
        src="./assets/images/Icon (2).svg"
        alt=""
      />
      <img
        class="padding38 firstimg"
        src="./assets/images/Group 5518.svg"
        alt=""
      />
    </div>
    <!-- <img class="padding38" src="./assets/images/Icon (2).svg" alt=""> -->
    <p class="title mbot10 center">{{ "Supportive Colleagues" | translate }}</p>
    <p class="gray17 center">
      {{ "Azimut Egypt’s Investment Team has a combined average experience of more than 15 years. Our investment professionals share their knowledge and market expertise to support you and help you grow." | translate }}
      
    </p>
  </div>
  <img
    class="absolute1 hideinmedia"
    src="./assets/images/Path 9 (1).svg"
    alt=""
  />
  <div class="col-lg-4 col-md-12 col-sm-12 col-12 center">
    <div class="imgtoggle">
      <img class="padding38 hoverimg" src="./assets/images/Icon-1.svg" alt="" />
      <img
        class="padding38 firstimg"
        src="./assets/images/Icon (4).svg"
        alt=""
      />
    </div>
    <!-- <img class="padding38" src="./assets/images/Icon (4).svg" alt=""> -->
    <p class="title mbot10 center">{{ "Autonomy and Ownership" | translate }}</p>
    <p class="gray17 center">
      {{ "Azimut Egypt’s Investment Team focuses on providing you flexibility on the projects you work on creating a work environment where your creativity has no limits." | translate }}
    </p>
  </div>
</div>

<!-- <div class="row zeromargin">
  <div class="col-12 side115 top175">
    <iframe class="videostyle" src="https://www.youtube.com/embed/rGeT9omYViQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
     encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div> -->

<div class="row zeromargin blackbg mtop70" dir="{{ 'dir' | translate }}">
  <div class="col-12">
    <div class="row zeromargin">
      <div class="col-12 top60">
        <p class="blue35 center">{{ "Open Job Positions" | translate }}</p>
        <!-- <p class="lightgray161 center textpadding">
           
        </p> -->
      </div>
    </div>

    <div class="row zeromargin top40">
      <div class="col-12 textpadding1">
        <div *ngIf="emptyState" class=" center ">
          <span class="lightgray161">{{ "Currently, Azimut Egypt does not have any available job vacancies. Kindly make sure to check our website frequently to stay updated. Also, you can send your resume’ to the following email" | translate }} </span><a href="mailto:i@azimut.eg" ><span class="lightgray161 underline">i@azimut.eg</span></a>
        </div>

        <div *ngIf="!emptyState">
          <div
            class="box1 top20"
            *ngFor="let career of listcareers; let i = index"
          >
            <div class="row zeromargin">
              <div class="col-lg-1 col-md-2 col-sm-2 col-2">
                <div
                  class="square3"
                  style="background-color:{{ career.color }}"
                >
                  <p class="center top15">{{ career.symbol }}</p>
                </div>
              </div>
              <div
                class="col-lg-8 col-md-8 col-sm-6 col-8 Rleft15 textdirection"
              >
                <div class="row zeromargin">
                  <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                    <p class="top13 Rbot0">{{ career.job }}</p>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <p class="top13 Rbot0 fulltext">{{ career.timing }}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 col-1 showinmedia">
                <img
                  class="rightfloat1"
                  src="./assets/images/Group 5245.svg"
                  alt=""
                />
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12 col-12 hideinmedia">
                <button
                  class="buttonstyle2"
                  type="button"
                  name="button"
                  (click)="gotoDynamic(career)"
                >
                  {{ "Apply Now" | translate }}
                </button>
                <img
                  class="rightfloat1 top20"
                  src="./assets/images/Group 5245.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="row zeromargin showinmedia Rtop30">
              <div class="col-12 center">
                <button
                  class="buttonstyle2"
                  type="button"
                  name="button"
                  (click)="gotoDynamic(career)"
                >
                  {{ "Apply Now" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row zeromargin top40 bot70">
      <div class="col-12">
        <!-- <p class="center blue16">View all job openings</p> -->
      </div>
    </div>
  </div>
</div>

<!-- <div class="row zeromargin mtop70 bot70">
    <div class="col-12">

      <div class="row zeromargin">
        <div class="col-12 top60 center ">
          <p class="black32 center">Stay in the know</p>
          <p class="lightgray16 center textpadding">Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit amet nisi erat.</p>
          <div class="sides310">
            <div class="border">
              <div class="row zeromargin">
                <div class="col-10">
                  <input class="inputsyle" type="text" name="" value="" placeholder="Enter your email address">
                </div>
                <div class="col-2">
                  <button class="submit" type="button" name="button"><img class="" src="./assets/images/Group 5245.svg" alt=""></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div> -->

<app-footer></app-footer>
