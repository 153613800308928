<div
  class="row zeromargin graybackground sidepadding top30 hideinmedia1 textdirection"
  dir="{{ 'dir' | translate }}"
>
  <div class="First_footer">
    <div class="pages_holder">
      <a href="./terms"
        ><p class="blue12 mright63">{{ "Terms of Use" | translate }}</p></a
      >
      <a href="./privacy"
        ><p class="blue12 mright63">{{ "Privacy" | translate }}</p></a
      >
      <a href="./assets/pdfs/FRA Guide Investors.pdf" target="_blank"
        ><p class="blue12 mright63">
          {{ "FRA Guide Investors" | translate }}
        </p></a
      >
      <a href="./assets/pdfs/FRA Guide Map.pdf" target="_blank"
        ><p class="blue12 mright63">{{ "FRA Guide Map" | translate }}</p></a
      >
    </div>
    <div class="socialmedia_holder">
      <span class="blue121 mright30">{{ "FOLLOW US" | translate }} </span>
      <a
        class="dflex"
        href="https://www.linkedin.com/company/azimut-egypt"
        target="_blank"
        ><img class="mright18" src="./assets/images/Group 4772.svg" alt=""
      /></a>
      <a
        class="dflex"
        href="https://www.facebook.com/AzimutEgypt"
        target="_blank"
        ><img class="mright18" src="./assets/images/Icon Path.svg" alt=""
      /></a>

      <a class="dflex" href="https://twitter.com/AzimutEgypt" target="_blank"
        ><img class="mright18" src="./assets/images/Icon Path (1).svg" alt=""
      /></a>
      <a
        class="dflex"
        href="https://www.instagram.com/azimutegypt"
        target="_blank"
        ><img class="mright18" src="./assets/images/Group 4771.svg" alt=""
      /></a>
      <a
        class="dflex"
        href="https://api.whatsapp.com/send?phone=+201066650549&text=Hello"
        target="_blank"
      >
        <img src="./assets/images/whatsapp.svg" alt="" />
      </a>
    </div>
  </div>

  <!-- <div class="col-8 zeropadding">
    <div class="row zeromargin">
      <div class="col-lg-2 col-md-12 col-sm-12 right31">
        <a href="./terms"
          ><p class="blue12">{{ "Terms of Use" | translate }}</p></a
        >
      </div>
      <div class="col-lg-2 col-md-12 col-sm-12 automargin">
        <a href="./privacy"
          ><p class="blue12">{{ "Privacy" | translate }}</p></a
        >
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12 automargin">
        <a href="./assets/pdfs/FRA Guide Investors.pdf" target="_blank"
        ><p class="blue12">{{ "FRA Guide Investors" | translate }}</p></a
        >
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12 automargin">
        <a href="./assets/pdfs/FRA Guide Map.pdf" target="_blank"
          ><p class="blue12">{{ "FRA Guide Map" | translate }}</p></a
        >
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row zeromargin mtop">
      <div class="col-4">
        <span class="blue121">{{ "FOLLOW US" | translate }} </span>
      </div>
      <div class="col-8 zeropadding">
        <div class="row zeromargin">
          <div class="col-2">
            <a
              href="https://www.linkedin.com/company/azimut-egypt"
              target="_blank"
              ><img src="./assets/images/Group 4772.svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://www.facebook.com/AzimutEgypt" target="_blank"
              ><img src="./assets/images/Icon Path.svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://twitter.com/AzimutEgypt" target="_blank"
              ><img src="./assets/images/Icon Path (1).svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://www.instagram.com/azimutegypt" target="_blank"
              ><img src="./assets/images/Group 4771.svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://api.whatsapp.com/send?phone=+201066650549&text=Hello" target="_blank">
            <img src="./assets/images/whatsapp.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>

<div
  class="footer_holder sidepadding graybackground tbpadding textdirection"
  dir="{{ 'dir' | translate }}"
>
  <div class="right_footer">
    <a href=""
      ><img
        *ngIf="lang == 'AR'"
        class="logo2size"
        src="./assets/images/logoarabic.png"
        alt=""
    /></a>
    <a href=""
      ><img
        *ngIf="lang == 'EN'"
        class="logo2size"
        src="./assets/images/AZENG.png"
        alt=""
    /></a>
    <!-- <img class="logo2size" src="./assets/images/double_011@2x.png" alt=""> -->
    <div class="showinmedia">
      <div class="row zeromargin top30">
        <div class="col-lg-2 col-md-12 col-sm-12 zeropadding textdirection">
          <a href="./terms"
            ><p class="blue12">{{ "Terms of Use" | translate }}</p></a
          >
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 zeropadding textdirection">
          <a href="./privacy"
            ><p class="blue12">{{ "Privacy" | translate }}</p></a
          >
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 zeropadding textdirection">
          <a href="./assets/pdfs/FRA Guide Investors.pdf" target="_blank"
            ><p class="blue12">{{ "FRA Guide Investors" | translate }}</p></a
          >
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 zeropadding textdirection">
          <a href="./assets/pdfs/FRA Guide Map.pdf" target="_blank"
            ><p class="blue12">{{ "FRA Guide Map" | translate }}</p></a
          >
        </div>
      </div>
    </div>
    <div class="address_holder">
      <p>
        <img class="infosize" src="./assets/images/Path 85.svg" alt="" />
        <span class="infostyle">{{ "Address" | translate }}</span>
      </p>
      <a href="https://goo.gl/maps/FcfbJhGNJkucBVSe9"><p class="textdirection">
        {{
          "Smart Village | Building B 16 | PO-Box 12577 | Giza, Egypt"
            | translate
        }}
      </p></a>
    </div>
  </div>

  <div class="left_footer">
    <div class="phone_holder">
      <p>
        <img class="infosize" src="./assets/images/Path 67.svg" alt="" />
        <span class="infostyle">{{ "Phone Number" | translate }}</span>
      </p>
      <a href="tel:+20235353535"><p class="ltrdirection">+20235353535</p></a>
    </div>
    <div class="email_holder">
      <p>
        <img class="infosize" src="./assets/images/Group 119.svg" alt="" />
        <span class="infostyle">{{ "E-mail" | translate }}</span>
      </p>
      <a href="mailto:i@azimut.eg"><p>i@azimut.eg</p></a>
    </div>
  </div>
</div>

<div
  class="row zeromargin showinmedia graybackground bot55 textdirection"
  dir="{{ 'dir' | translate }}"
>
  <div class="col-12 sidepadding">
    <div class="row zeromargin mtop">
      <div class="col-4">
        <span class="blue121">{{ "FOLLOW US" | translate }}</span>
      </div>
      <div class="col-7">
        <div class="row zeromargin">
          <div class="col-2">
            <a
              href="https://www.linkedin.com/company/azimut-egypt"
              target="_blank"
              ><img src="./assets/images/Group 4772.svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://www.facebook.com/AzimutEgypt" target="_blank"
              ><img src="./assets/images/Icon Path.svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://twitter.com/AzimutEgypt" target="_blank"
              ><img src="./assets/images/Icon Path (1).svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a href="https://www.instagram.com/azimutegypt" target="_blank"
              ><img src="./assets/images/Group 4771.svg" alt=""
            /></a>
          </div>
          <div class="col-2">
            <a
              href="https://api.whatsapp.com/send?phone=+201066650549&text=Hello"
              target="_blank"
            >
              <img src="./assets/images/whatsapp.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row zeromargin block lightgraybackground textdirection"
  dir="{{ 'dir' | translate }}"
>
  <div class="col-12">
    <p class="center footertext top5bot4 zeromargin">
      ©
      {{
        "2020 Azimut Egypt Asset Management S.A.E part of the Azimut Group. All rights reserved"
          | translate
      }}
    </p>
  </div>
</div>
