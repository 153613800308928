import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FundpageService {

  constructor(private http: HttpClient) { }

  getfund(requestId): Observable<any> {
    return this.http.get(environment.apiUrl + `/list/funds/${requestId}`);
  }

  submitEmail(body): Observable<any> {
    return this.http.post(environment.apiUrl + `/sendMail`, body);
  }

}
