<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="whoweare-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">{{ "who we are" | translate }}</p>
      <p class="subheadertext center">
        {{ "KNOW-HOW, EXPERIENCE AND UNCONDITIONAL VALUES" | translate }}
      </p>
    </div>
  </div>
</div>

<div class="row zeromargin loader-cont" dir="{{ 'dir' | translate }}">
  <div class="col-12 textpadding">
    <p class="blue35 textdirection">
      {{ listHeaders?.philosophy.name }}
    </p>

    <div
      class="philotext textdirection"
      [innerHtml]="listHeaders?.philosophy.content"
    ></div>
  </div>
</div>

<nz-tabset class="sliderdirection">
  <nz-tab nzTitle="{{ 'AZIMUT EGYPT' | translate }}">
    <div class="row zeromargin">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 showinmedia">
        <img class="fullsize1" src="{{ listHeaders?.vision.img }}" alt="" />
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 Rtop35">
        <div class="row zeromargin">
          <span class="header milefloat">{{ listHeaders?.vision.name }}</span>
        </div>
        <div
          class="vision textdirection"
          [innerHtml]="listHeaders?.vision.content"
        ></div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 hideinmedia">
        <img class="fullsize1" src="{{ listHeaders?.vision.img }}" alt="" />
      </div>
    </div>

    <div class="row zeromargin top50 sliderminheight">
      <div class="col-12 bot20">
        <span class="header milefloat">{{ "MILESTONE" | translate }}</span>
      </div>

      <div class="row zeromargin fullwidth">
        <div class="col-12 colalign">
          <mdb-carousel
            [isControls]="true"
            class="carousel-multi-item multi-animation"
            [type]="'carousel-multi-item'"
            [animation]="'slide'"
            [interval]="intervalNumber"
          >
            <mdb-carousel-item
              class=""
              *ngFor="let milestone of slides; let i = index"
            >
              <div
                class="Mright35"
                [ngClass]="{ 'd-none d-md-block': cardIndex !== 0 }"
                *ngFor="let card of milestone; let cardIndex = index"
              >
                <img class="milestoneimg" src="{{ card.img }}" alt="" />
                <p title="{{ card.title }}" class="center milename">
                  {{ card.title }}
                </p>
                <p class="center miledate">{{ card.date }}</p>
              </div>
            </mdb-carousel-item>
          </mdb-carousel>
        </div>
      </div>

      <!-- <carousel class="milestone">
        <div
          class="carousel-cell"
          *ngFor="let milestone of listmilestone; let i = index"
        >
          <img class="" src="{{ milestone.img }}" alt="" />
          <p class="center milename">{{ milestone.title }}</p>
          <p class="center miledate">{{ milestone.date }}</p>
        </div>
      </carousel> -->
    </div>

    <div class="row zeromargin top50">
      <div class="col-12">
        <p class="header textdirection">
          {{ listHeaders?.aums.name }}
        </p>
        <div
          class="vision textdirection"
          [innerHtml]="listHeaders?.aums.content"
        ></div>
      </div>
    </div>
  </nz-tab>

  <nz-tab nzTitle="{{ 'AZIMUT GROUP' | translate }}">
    <div class="row zeromargin">
      <div class="col-6 col-md-12 col-sm-12 col-12 showinmedia">
        <img class="fullsize1" src="{{ listHeaders?.about.img }}" alt="" />
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 Rtop35 textdirection">
        <span class="header">{{ listHeaders?.about.name }}</span>
        <div class="vision" [innerHtml]="listHeaders?.about.content"></div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 hideinmedia">
        <img class="fullsize1" src="{{ listHeaders?.about.img }}" alt="" />
      </div>
    </div>
  </nz-tab>

  <nz-tab
    (nzSelect)="setSlider()"
    nzTitle="{{ 'BOARD OF DIRECTORS' | translate }}"
  >
    <div class="slidermargin">
      <div class="bodpadding">
        <div class="cascade-slider_container hideinmedia" id="cascade-slider">
          <div class="cascade-slider_slides">
            <div
              class="cascade-slider_item"
              *ngFor="let director of listDirectors; let i = index"
            >
              <div class="director-cont">
                <div class="row bot20">
                  <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                    <img
                      class="directorimg"
                      src="{{ director.img }}"
                      onError="this.src='https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'"
                      alt=""
                    />
                  </div>
                  <div
                    class="col-lg-9 col-md-12 col-sm-12 col-12 textdirection"
                  >
                    <span class="directorname">{{ director.name }} </span
                    ><span class="degree"> {{ director.cert }}</span>
                    <p class="directorposition">{{ director.position }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="borderbottom"></div>
                </div>
                <div
                  class="director textdirection"
                  [innerHtml]="director.portfolio"
                ></div>
              </div>
            </div>
          </div>

          <ol class="cascade-slider_nav hideinmedia">
            <li
              class="cascade-slider_dot"
              *ngFor="let director of listDirectors; let i = index"
            >
              <img
                src="{{ director.img }} "
                onError="this.src='https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'"
              />
            </li>
          </ol>

          <span
            class="cascade-slider_arrow cascade-slider_arrow-left"
            data-action="prev"
            ><img
              class="arrowdirectorsize"
              src="./assets/images/right-arrow.svg"
              style="transform: rotate(180deg)"
              alt=""
          /></span>
          <span
            class="cascade-slider_arrow cascade-slider_arrow-right"
            data-action="next"
            ><img
              class="arrowdirectorsize"
              src="./assets/images/right-arrow.svg"
              alt=""
          /></span>
        </div>
      </div>

      <div class="showinmedia">
        <mdb-carousel
          [isControls]="true"
          class="carousel-multi-item multi-animation"
          [type]="'carousel-multi-item'"
          [animation]="'slide'"
          [interval]="7000"
        >
          <mdb-carousel-item
            class=""
            *ngFor="let listDirectors of slidesdirector; let i = index"
          >
            <div
              class=""
              [ngClass]="{ 'd-none d-md-block': cardIndex !== 0 }"
              *ngFor="let card of listDirectors; let cardIndex = index"
            >
              <div class="director-cont">
                <div class="row bot20">
                  <div class="col-lg-2 col-md-12 col-sm-12 col-12">
                    <img
                      class="directorimg"
                      src="{{ card.img }}"
                      alt=""
                      onError="this.src='https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'"
                    />
                  </div>
                  <div
                    class="col-lg-10 col-md-12 col-sm-12 col-12 textdirection"
                  >
                    <p class="directorname">{{ card.name }}</p>
                    <p class="directorposition">{{ card.position }}</p>
                    <p class="degree">{{ card.cert }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="borderbottom"></div>
                </div>
                <div
                  class="director textdirection"
                  [innerHtml]="card.portfolio"
                ></div>
              </div>
            </div>
          </mdb-carousel-item>
        </mdb-carousel>

        <!-- <nz-carousel [nzEffect]="effect">
        <div
          nz-carousel-content
          class="sidepadding"
          *ngFor="let director of listDirectors; let i = index"
        >
          <div class="director-cont">
            <div class="row bot20">
              <div class="col-lg-2 col-md-12 col-sm-12 col-12">
                <img class="directorimg" src="{{ director.img }}" alt="" />
              </div>
              <div class="col-lg-10 col-md-12 col-sm-12 col-12 textdirection">
                <p class="directorname">{{ director.name }}</p>
                <p class="directorposition">{{ director.position }}</p>
                <p class="degree">{{ director.cert }}</p>
              </div>
            </div>
            <div class="row">
              <div class="borderbottom"></div>
            </div>
            <div
              class="director textdirection"
              [innerHtml]="director.portfolio"
            ></div>
          </div>
        </div>
      </nz-carousel> -->
      </div>
    </div>
  </nz-tab>

  <nz-tab nzTitle="{{ 'OUR TOP CLIENTS' | translate }}">
    <div class="row zeromargin">
      <div class="col-12 center">
        <span class="header arabictextdirection"
          >{{ "AZIMUT" | translate }}
          <span class="header right5">{{ "TOP CLIENTS" | translate }}</span></span
        >
        <div class="right90 top20 center client clienttextpadding" [innerHtml]="listHeaders?.clients.content">
          
        </div>
      </div>
    </div>
    <div class="row zeromargin fullwidth">
      <div class="col-12 colalign">
        <mdb-carousel
          [isControls]="true"
          class="carousel-multi-item multi-animation"
          [type]="'carousel-multi-item'"
          [animation]="'slide'"
          [interval]="5000"
        >
          <mdb-carousel-item
            class=""
            *ngFor="let listClients of slidesclinet; let i = index"
          >
            <div
              class="Mright350 imghover_holder"
              [ngClass]="{ 'd-none d-md-block': cardIndex !== 0 }"
              *ngFor="let card of listClients; let cardIndex = index"
            >
              <div class="imghover relative">
                <img class="cilentimg" src="{{ card.img }}" alt="" />
                <div class="row zeromargin cilentditals">
                  <div class="col-12 lefttext colposition">
                    <p class="clinetname">{{ card.name }}</p>
                    <div class="imgborderbottom"></div>
                    <a href="{{ card.desc }}" target="_blank"
                      ><p class="fs12">{{ card.desc }}</p></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </mdb-carousel-item>
        </mdb-carousel>
      </div>
    </div>

    <!-- <carousel class="topclient">
      <div
        class="carousel-cell"
        *ngFor="let client of listClients; let i = index"
      >
        <div class="imghover">
          <img class="cilentimg relative" src="{{ client.img }}" alt="" />
          <div class="row zeromargin cilentditals">
            <div class="col-12">
              <p class="clinetname">{{ client.name }}</p>
              <div class="imgborderbottom"></div>
              <p class="fs12">{{ client.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </carousel> -->
  </nz-tab>

  <nz-tab nzTitle="{{ 'CAREERS' | translate }}">
    <div class="row zeromargin">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 showinmedia center">
        <img
          class="fullsize2"
          src="{{ listHeaders?.career.img }}"
          alt=""
        />
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 Rtop35">
        <div class="row zeromargin">
          <div class="col-12 zeropadding textdirection">
            <span class="header"
              >{{ "Job" | translate
              }}<span class="header"> {{ "Openings" | translate }}</span></span
            >
            <p class="right90 top20 maxwidth410">
              {{
                "Interested in joining our investment crew? Click the tab below to check our job vacancies."
                  | translate
              }}
            </p>
            <a href="./careers"
              ><button class="buttonstyle1" type="button" name="button">
                <span>{{
                  "CHECK OUT OUR OPEN OPPORTUNITIES" | translate
                }}</span>
                <img
                  class="top6"
                  src="./assets/images/Group 5245.svg"
                  alt=""
                /></button
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 hideinmedia">
        <img
          class="fullsize2"
          src="{{ listHeaders?.career.img }}"
          alt=""
        />
      </div>
    </div>
  </nz-tab>
</nz-tabset>

<div class="history-cont" dir="{{ 'dir' | translate }}">
  <div class="row zeromargin sidepadding top50">
    <div class="col-lg-7 col-md-12 col-sm-12 col-12">
      <p class="blue35 textdirection">
        {{ listHeaders?.history.name }}
      </p>
      <div
        class="history textdirection"
        [innerHtml]="listHeaders?.history.content"
      ></div>
    </div>
    <div class="col-lg-5 col-md-12 col-sm-12 col-12">
      <img class="fullsize" src="{{ listHeaders?.history.img }}" alt="" />
    </div>
  </div>

  <div class="row zeromargin sidepadding top125 hideinmedia">
    <div class="col-12 zeropadding">
      <div class="row zeromargin">
        <div class="col-5 zeropadding">
          <div class="row zeromargin fullheight">
            <div class="col-4 borderleft">
              <p class="black12">
                {{ "DELTA Brokerage Established" | translate }}
              </p>
            </div>
            <div class="col-4"></div>
            <div class="col-4 borderleft">
              <p class="black12">
                {{
                  "ABN AMRO bought out EAB, becoming a 59% partner Re-Branding to ABN AMRO Delta Asset Management"
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-7 zeropadding">
          <div class="row zeromargin fullheight">
            <div class="col-3"></div>
            <div class="col-3 borderleft">
              <p class="black12">
                {{ "Rasmala Acquires 51% from Delta Group" | translate }}
              </p>
            </div>
            <div class="col-3"></div>
            <div class="col-3 borderleft">
              <p class="black12">
                {{
                  "Azimut acquires 100% of Rasmala Egypt Asset Management"
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row zeromargin sidepadding top30 hideinmedia1">
    <div class="col-5 zeropadding">
      <div class="row zeromargin fullheight">
        <div class="col-4 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">1994</p>
              </div>
            </div>
            <div class="col-8 side10">
              <div class="lightblueline"></div>
            </div>
          </div>
        </div>

        <div class="col-4 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">1996</p>
              </div>
            </div>
            <div class="col-8 side10">
              <div class="lightblueline"></div>
            </div>
          </div>
        </div>

        <div class="col-4 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">1999</p>
              </div>
            </div>
            <div class="col-8 side10">
              <div class="lightblueline"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-7 zeropadding">
      <div class="row zeromargin fullheight">
        <div class="col-3 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">2001</p>
              </div>
            </div>
            <div class="col-8 side10">
              <div class="lightblueline"></div>
            </div>
          </div>
        </div>

        <div class="col-3 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">2007</p>
              </div>
            </div>
            <div class="col-8 side10">
              <div class="lightblueline"></div>
            </div>
          </div>
        </div>

        <div class="col-3 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">2015</p>
              </div>
            </div>
            <div class="col-8 side10">
              <div class="lightblueline"></div>
            </div>
          </div>
        </div>

        <div class="col-3 zeropadding">
          <div class="row zeromargin">
            <div class="col-4 zeropadding">
              <div class="datebox">
                <p class="center datetext zeromargin">2019</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row zeromargin sidepadding top30 bot120 hideinmedia">
    <div class="col-12 zeropadding">
      <div class="row zeromargin">
        <div class="col-5 zeropadding">
          <div class="row zeromargin fullheight">
            <div class="col-4"></div>
            <div class="col-4 borderleft">
              <p class="black12">
                {{
                  "EAB became a 40% partner in the brokerage company and established Delta EAB Asset Management Company"
                    | translate
                }}
              </p>
            </div>
            <div class="col-4"></div>
          </div>
        </div>

        <div class="col-7 zeropadding">
          <div class="row zeromargin fullheight">
            <div class="col-3 borderleft">
              <p class="black12">
                {{
                  "ABN AMRO decided to curtail its brokerage business worldwide  & retained the Asset Management partnership"
                    | translate
                }}
              </p>
            </div>
            <div class="col-3"></div>
            <div class="col-3 borderleft">
              <p class="black12">
                {{
                  "Rasmala Acquires the remaining 49% from Delta Group"
                    | translate
                }}
              </p>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row zeromargin Rbot20 showinmedia">
    <div class="col-12">
      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">1994</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{ "DELTA Brokerage Established" | translate }}
          </p>
        </div>
      </div>

      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">1996</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{
              "EAB became a 40% partner in the brokerage company and established Delta EAB Asset Management Company"
                | translate
            }}
          </p>
        </div>
      </div>

      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">1999</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{
              "ABN AMRO bought out EAB, becoming a 59% partner Re-Branding to ABN AMRO Delta Asset Management"
                | translate
            }}
          </p>
        </div>
      </div>

      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">2001</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{
              "ABN AMRO decided to curtail its brokerage business worldwide  & retained the Asset Management partnership"
                | translate
            }}
          </p>
        </div>
      </div>

      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">2007</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{ "Rasmala Acquires 51% from Delta Group" | translate }}
          </p>
        </div>
      </div>

      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">2015</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{
              "Rasmala Acquires the remaining 49% from Delta Group" | translate
            }}
          </p>
        </div>
      </div>

      <div class="row zeromargin Rtop50">
        <div class="col-3">
          <div class="datebox">
            <p class="center datetext zeromargin">2019</p>
          </div>
        </div>
        <div class="col-8 borderleft">
          <p class="black12">
            {{
              "Azimut acquires 100% of Rasmala Egypt Asset Management"
                | translate
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<script>
  $(".gallery-responsive").slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
</script>

<!-- <script type="text/javascript">
  $(function () {
    $("#aniimated-thumbnials").lightGallery({
      thumbnail: true,
    });
    // Card's slider
    var $carousel = $(".slider-for");

    $carousel.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      adaptiveHeight: true,
      asNavFor: ".slider-nav",
    });
    $(".slider-nav").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      variableWidth: true,
    });
  });
</script> -->
