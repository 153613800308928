<app-header></app-header>
<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="fundpage-cont">
  <div class="row zeromargin">
    <div class="col-12 top40">
      <p class="blue15SB center">{{ listeachfund?.asset_name }}</p>
      <p class="headertext center">{{ listeachfund?.name }}</p>
      <div class="row zeromargin bot40">
        <div
          class=" righttextalign reversearabic1"
        >
          <span class="gray20"> {{ "RIC :" | translate }}</span
          ><span class="white22"> {{ listeachfund?.RIC }} </span>
        </div>
        <div
          class=" Rcenter bbg Rtop10 reversearabic"
        >
          <span class="gray20"> {{ "BBG Ticker :" | translate }}</span
          ><span class="white22"> {{ listeachfund?.bbg }} </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row zeromargin lightgraybg textdirection"
  dir="{{ 'dir' | translate }}"
>
  <div class="col-12 pagepadding">
    <div class="row zeromargin">
      <div class="col-lg-3 col-md-12 col-sm-12 col-12 Rpadding0">
        <div class="sicky">
          <a class="hideinmedia1" href="./funds"
            ><img
              class="arrowdirection"
              src="./assets/images/Group 4846.svg"
              alt=""
            />
            <span class="left11 hideinmedia1 black14SB">{{
              "Back" | translate
            }}</span></a
          >
          <div class="assetsbox mtop15">
            <p class="gray14SB">{{ "On this page" | translate }} :</p>
            <p class="Mbot10 pointer white14SB" (click)="toINFO()">
              {{ "INFO" | translate }}
            </p>
            <p class="Mbot10 pointer white14SB" (click)="toOBJECTIVE()">
              {{ "INVESTMENT OBJECTIVE" | translate }}
            </p>
            <p class="Mbot10 pointer white14SB" (click)="totype()">
              {{ "FUND DETAILS" | translate }}
            </p>
            <p class="Mbot10 pointer white14SB" (click)="toPERFORMANCE()">
              {{ "PERFORMANCE AND PORTFOLIO" | translate }}
            </p>
            <p class="Mbot10 pointer white14SB" (click)="toENTITIES()">
              {{ "RECEIVING ENTITIES" | translate }}
            </p>
            <p class="Mbot10 pointer white14SB" (click)="toMANAGER()">
              {{ "PORTFOLIO MANAGER" | translate }}
            </p>
            <p class="Mbot10 pointer white14SB" (click)="toDOCUMENTS()">
              {{ "DOCUMENTS" | translate }}
            </p>
          </div>
          <div class="row zeromargin bluebg all15">
            <div class="col-12 zeropadding">
              <img
                class="leftfloat"
                *ngIf="!watchList"
                src="./assets/images/star.svg"
                alt=""
              />
              <img
                class="leftfloat"
                *ngIf="watchList"
                src="./assets/images/Group 5124.svg"
                alt=""
              />
              <span
                (click)="addToWatchList()"
                *ngIf="!watchList"
                class="white14SBUL rightfloat"
                >{{ "Add to watchlist" | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12 col-12">
        <div class="row zeromargin blackboxbg Rtop28" id="INFO">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="row zeromargin itemcenter">
              <span class="lightgray12SB Mtop6">{{ "NAV" | translate }} </span
              ><span class="white20BL left5">
                {{ listeachfund?.last_nav.nav }}
                {{ listeachfund?.currency_symbol }}
              </span>
            </div>
            <div class="row zeromargin top10 itemcenter">
              <span class="lightgray12SB">{{ "Date" | translate }} :</span>
              <span class="white14SB left5">{{
                listeachfund?.last_nav.date
              }}</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 leftborder left25">
            <div class="row zeromargin Rtop10 itemcenter">
              <span class="lightgray12SB"
                >{{ "Base currency" | translate }} :</span
              ><span class="white14SB left5">{{
                listeachfund?.currency_name
              }}</span>
            </div>
            <div class="row zeromargin top10 itemcenter">
              <span class="lightgray12SB">
                {{ "Pricing Frequency" | translate }} :</span
              ><span class="white14SB left5">{{
                listeachfund?.pricing_freq
              }}</span>
            </div>
          </div>
        </div>

        <div class="row zeromargin top40" id="OBJECTIVE">
          <div class="col-12 zeropadding">
            <p class="blue24B">{{ "INVESTMENT OBJECTIVE" | translate }}</p>
            <p class="gray14 top10">{{ listeachfund?.objective }}</p>
          </div>
        </div>

        <nz-collapse id="FUNDDETAILS">
          <nz-collapse-panel nzHeader="{{ 'FUND DETAILS' | translate }}">
            <div *ngFor="let detail of details; let i = index">
              <div class="row zeromargin">
                <div class="col-lg-8 col-md-8 col-sm-8 col-8 zeroleft">
                  <span class="text1details">{{ detail.key }}</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-4 zeroright">
                  <span class="rightfloat text2details">{{
                    detail.value
                  }}</span>
                </div>
              </div>
              <div class="row zeromargin topbot11">
                <div class="col-12 zeropadding">
                  <div class="borderbottom"></div>
                </div>
              </div>
            </div>
            <div class="row zeromargin">
              <div class="col-12 zeroleft">
                <p class="gray9 zeromargin">
                  {{
                    "Note : Please refer to the Fund Prospectus for more details"
                      | translate
                  }}.
                </p>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse id="PERFORMANCE">
          <nz-collapse-panel
            nzHeader="{{ 'PERFORMANCE AND PORTFOLIO' | translate }}"
          >
            <p class="gray18SB">{{ "Performance" | translate }}</p>
            <p class="top20 gray18SB">
              {{ "Growth of Investment" | translate }}
            </p>

            <div class="row zeromargin">
              <div class="col-12 borderbuttons">
                <button
                  class="graphbuttons"
                  (click)="updateOptions('1m')"
                  [class.active]="activeOptionButton == '1m'"
                >
                  1M
                </button>
                <button
                  class="graphbuttons"
                  (click)="updateOptions('3m')"
                  [class.active]="activeOptionButton == '3m'"
                >
                  3M
                </button>
                <button
                  class="graphbuttons"
                  (click)="updateOptions('6m')"
                  [class.active]="activeOptionButton == '6m'"
                >
                  6M
                </button>
                <button
                  class="graphbuttons"
                  (click)="updateOptions('1y')"
                  [class.active]="activeOptionButton == '1y'"
                >
                  1Y
                </button>

                <button
                  class="graphbuttons"
                  (click)="updateOptions('5y')"
                  [class.active]="activeOptionButton == '5y'"
                >
                  5Y
                </button>
                <button
                  class="graphbuttons"
                  (click)="updateOptions('10y')"
                  [class.active]="activeOptionButton == '10y'"
                >
                  10Y
                </button>

                <button
                  class="graphbuttons"
                  (click)="updateOptions('all')"
                  [class.active]="activeOptionButton == 'all'"
                >
                  All
                </button>
              </div>
            </div>
            <apx-chart
              #chart
              [series]="chartOptions2?.series"
              [chart]="chartOptions2?.chart"
              [colors]="chartOptions2?.colors"
              [yaxis]="chartOptions2?.yaxis"
              [dataLabels]="chartOptions2?.dataLabels"
              [markers]="chartOptions2?.markers"
              [stroke]="chartOptions2?.stroke"
              [grid]="chartOptions2?.grid"
              [xaxis]="chartOptions2?.xaxis"
              [tooltip]="chartOptions2?.tooltip"
              [annotations]="chartOptions2?.annotations"
            ></apx-chart>

            <div>
              <h2 class="Guideline">
                {{ "Investment Guideline" | translate }}
              </h2>
              <nz-tabset>
                <nz-tab nzTitle="{{ 'Pie Chart' | translate }}">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div class="stock_holder">
                        <span>{{ "Asset Allocation" | translate }}</span>
                        <span>{{ "Weight%" | translate }}</span>
                      </div>

                      <div
                        *ngFor="let item of chartObject"
                        class="stock_circle_holder"
                      >
                        <span
                          ><span class="stock_circle"  [ngStyle]="{'border-color': item.color}"></span>
                          <span class="title_span">{{ item.key }}</span></span
                        >
                        <span class="number_span">{{ item.value }}</span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div style="display: block">
                        <div id="chart">
                          <apx-chart
                            [series]="chartOptions?.series"
                            [dataLabels]="chartOptions?.dataLabels"
                            [chart]="chartOptions?.chart"
                            [labels]="chartOptions?.labels"
                            [responsive]="chartOptions?.responsive"
                            [colors]="chartOptions?.colors"
                          ></apx-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-tab>

                <nz-tab nzTitle="{{ 'Bar Chart' | translate }}">
                  <div id="chart" class="textorigin">
                    <apx-chart
                      [series]="chartOptions3?.series"
                      [chart]="chartOptions3?.chart"
                      [dataLabels]="chartOptions3?.dataLabels"
                      [plotOptions]="chartOptions3?.plotOptions"
                      [xaxis]="chartOptions3?.xaxis"
                      [yaxis]="chartOptions3?.yaxis"

                    ></apx-chart>
                  </div>
                </nz-tab>
              </nz-tabset>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse id="ENTITIES">
          <nz-collapse-panel nzHeader="{{ 'RECEIVING ENTITIES' | translate }}">
            <div class="row zeromargin">
              <div
                class="col-lg-3 col-md-6 col-sm-6 col-6"
                *ngFor="let entity of listeachfund?.entity; let i = index"
              >
                <div class="row zeromargin">
                  <div class="col-12 zeropadding center circle2">
                    <img class="circle" src="{{ entity.logo }}" alt="" />
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 zeropadding">
                    <p class="bankname center mbot6">{{ entity.name }}</p>
                    <a href="{{ entity.link }}" target="_blank"
                      ><p class="blue15SBUL center">
                        {{ "Visit" | translate }}
                      </p></a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row zeromargin top65">
              <div class="col-12 center">
                <span class="text1">{{ "If you dont have a" | translate }}</span
                ><span class="text2 left5">{{
                  "Bank Account" | translate
                }}</span>
              </div>
            </div>
            <div class="row zeromargin top10 bot30">
              <div class="col-12 center">
                <a *ngIf="listeachfund?.entities_form == null">
                  <button class="bankbutton" type="button" name="button">
                    {{ "Download PDF Form" | translate }}
                  </button></a
                >
                <a
                  *ngIf="listeachfund?.entities_form != null"
                  href="{{ listeachfund?.entities_form }}"
                  target="_blank"
                  ><button class="bankbutton" type="button" name="button">
                    {{ "Download PDF Form" | translate }}
                  </button></a
                >
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse id="MANAGER">
          <nz-collapse-panel nzHeader="{{ 'PORTFOLIO MANAGER' | translate }}">
            <div class="row zeromargin">
              <div
                class="col-lg-4 col-md-12 col-sm-12 col-12 center"
                *ngFor="let managers of listeachfund?.managers; let i = index"
              >
                <div class="row zeromargin">
                  <div class="col-12 zeropadding">
                    <img class="mangerimg" src="{{ managers.img }}" alt="" />
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 zeropadding">
                    <p class="center mangername">{{ managers.name }}</p>
                    <p class="center job">{{ managers.title }}</p>
                    <p class="center country">{{ managers.origin }}</p>
                    <p class="center date">
                      {{ "In Azimut since" | translate }} {{ managers.joined }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <div class="row zeromargin top30" id="DOCUMENTS">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12 zeropadding">
            <p class="black24B">{{ "RELATED DOCUMENTS" | translate }}</p>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 col-12 zeropadding">
            <div class="row zeromargin">
              <div class="col-6 zeroright">
                <button
                  class="dimmedbutton"
                  type="button"
                  name="button"
                  disabled
                  *ngIf="!allowDownload"
                >
                  {{ "Send by email" | translate }}
                  <img class="mleft10" src="./assets/images/email.svg" alt="" />
                </button>

                <button
                  (click)="sendmail()"
                  class="dimmedbutton active_btn"
                  type="button"
                  name="button"
                  *ngIf="allowDownload"
                >
                  {{ "Send by email" | translate }}
                  <img class="mleft10" src="./assets/images/email.svg" alt="" />
                </button>
              </div>
              <div class="col-6 zeroright">
                <button
                  class="dimmedbutton"
                  type="button"
                  name="button"
                  disabled
                  *ngIf="!allowDownload"
                >
                  {{ "Download selected" | translate }}
                  <img
                    class="mleft10"
                    src="./assets/images/download (1).svg"
                    alt=""
                  />
                </button>

                <button
                  *ngIf="allowDownload"
                  class="dimmedbutton active_btn"
                  type="button"
                  name="button"
                  (click)="log2()"
                >
                  {{ "Download selected" | translate }}
                  <img
                    class="mleft10"
                    src="./assets/images/download (1).svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row zeromargin top10">
          <nz-checkbox-wrapper style="width: 100%" (nzOnChange)="log($event)">
            <div class="row zeromargin">
              <div
                nz-col
                *ngFor="let item of files"
                class="col-lg-4 col-md-12 col-sm-12 col-12 zeroleft Rtop100 Rpadding0"
              >
                <div class="checkbox_holder blackbox">
                  <label
                    nz-checkbox
                    nzValue="{{
                      item.download_link + '/' + item.original_name
                    }}"
                  >
                    <div class="details_holder">
                      <div>
                        <span class="SB11" title="{{ item.original_name }}">{{
                          item.original_name
                        }}</span>
                        <span class="rightfloatt SB9">PDF</span>
                      </div>

                      <div>
                        <!-- <span class="SB11">EN</span> -->
                        <span class="rightfloatt SB9">{{ item.size }}</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </nz-checkbox-wrapper>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  type="button"
  id="openmodal"
  data-toggle="modal"
  data-target="#myModal"
  style="display: none"
>
  Open Large Modal
</button>
<div
  class="modal fade textdirection"
  id="myModal"
  role="dialog"
  dir="{{ 'dir' | translate }}"
  data-keyboard="false"

>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
        <p class="center modalheader">
          {{ "SHARE FUNDS DOCUMENT" | translate }}
        </p>
      </div>
      <!-- <p class="center modalsecondheader">YOU HAVE 1 DOCUMENTS SELECTED</p> -->
      <div class="modal-body send-mail-model">
        <div class="row zeromargin">
          <div
            nz-col
            *ngFor="let item of filesNames"
            class="col-lg-4 col-md-12 col-sm-12 col-12 zeroleft Rtop100 Rpadding0 Mtop15"
          >
            <div class="checkbox_holder blackbox">
              <div class="details_holder1">
                <div>
                  <span class="SB11" title="{{ item.name }}">{{
                    item.name
                  }}</span>
                  <span class="rightfloatt SB9">PDF</span>
                </div>

                <div>
                  <span class="SB11">EN</span>
                  <span class="rightfloatt SB9">{{ item.size }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="sendtextmodal center">
        {{ "Send document by email" | translate }}
      </p>
      <div class="row zeromargin">
        <div class="col-12">
          <input
            class="modalinput"
            type="text"
            name=""
            id=""
            [(ngModel)]="emailName"
            placeholder="{{ 'Type an Email' | translate }}"
          />
        </div>
      </div>
      <div class="row zeromargin">
        <div class="col-12 center">
          <button class="modalbutton" (click)="submitMail()">
            {{ "Send Documents" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
