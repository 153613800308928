import { Component, OnInit } from '@angular/core';
import { CareersService } from './service/careers.service';
import { Careers } from "./data/data";
import {​​​​​​​​ Router,ActivatedRoute }​​​​​​​​ from'@angular/router';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  listHeaders: any;

  constructor(private _careersApiService: CareersService,private route: ActivatedRoute,private router : Router) { }
  loader: boolean = true;
  emptyState = false;

  listcareers: Careers[];
  gotoDynamic(career) {
    //this.router.navigateByUrl('/dynamic', { state: { id:1 , name:'Angular' } });
    // this.router.navigateByUrl('/subcareers', { state: "kkkkkkkkkkkkkkkkkkkkk" });
    this.router.navigate(['/subcareers',{requestId: career.id}]);
  }
  ngOnInit(): void {

    this._careersApiService.getcareers().subscribe((data) => {
      this.loader = true;
      this.emptyState = true;



      if (data.status.code == 200) {
        this.loader = false;
        if (data.data.length == 0) {
          this.emptyState = true;
        } else {
          this.loader = false;
          this.emptyState = false;
        }


        this.listcareers = data.data;
      }
    });

    this._careersApiService.getheaders().subscribe((data) => {
      if (data.status.code == 200) {
        this.listHeaders = data.data;
      }
    });
  }

}
