import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError } from "rxjs/operators";
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class Interceptor implements HttpInterceptor {
 // constructor(private message: NzMessageService) {}

 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 let tokenReq = req.clone({
 setHeaders:{
 Authorization: 'Bearer xx.yy.zz',
 'Accept-Language': 'en',
 locale:localStorage.getItem("lang")

 
 }
 })
 return next.handle(tokenReq).pipe(
 catchError((error: HttpErrorResponse) => {
 // this.message.info('This is a normal message');
 return throwError(error);
 })
 );
 }
}
