import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubcareersService } from './service/subcareers.service';
import { EachCareer } from './data/data';
// import { Submit } from './data/data';
import { ToastrService } from 'ngx-toastr';
// import { NzMessageService } from 'ng-zorro-antd/message';

import {
  FormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'app-subcareers',
  templateUrl: './subcareers.component.html',
  styleUrls: ['./subcareers.component.css'],
})
export class SubcareersComponent implements OnInit {
  constructor(
    private _eachcareersApiService: SubcareersService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public toastr: ToastrService
  ) {}

  listeachcareers: EachCareer;
  loader: boolean = true;
  fileName: any= '';

  public Submit: any = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: '',
    cv: '',
    career_id: '',
  };
  public submitapplication: any = false;
  public formData = new FormData();
  registerForm: FormGroup;

  toform() {
    document.getElementById('applyform').scrollIntoView();
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    let requestId = this.route.snapshot.paramMap.get('requestId');
    this._eachcareersApiService.getcareer(requestId).subscribe((data) => {
      this.loader = true;
      if (data.status.code == 200) {
        this.loader = false;
        this.listeachcareers = data.data;
      }
    });
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      cv: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });
  }

  insertuserr() {
    this.loader = true;
    let requestId = this.route.snapshot.paramMap.get('requestId');
    this.formData.append('first_name', this.Submit.first_name);
    this.formData.append('last_name', this.Submit.last_name);
    this.formData.append('email', this.Submit.email);
    this.formData.append('phone', this.Submit.phone);
    this.formData.append('message', this.Submit.message);
    this.formData.append('career_id', requestId);
    this._eachcareersApiService.insertuser(this.formData).subscribe((data) => {
      if (data['status'].code == '200') {
        this.toastr.success(data['status'].message);
        this.loader = false;
      } else {
        this.toastr.error(data['status'].message);
        this.loader = false;
      }
    });
  }

  fileReader = (event) => {
    var input = event.target;
    var reader = new FileReader();
    reader.onload = function () {
      var dataURL = reader.result;
    };
    this.fileName = input.files[0].name;
    this.formData.append('cv', input.files[0]);
  };
}
