import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  ngOnInit(): void {
    window.scrollTo(0,0);
    let lang = localStorage.getItem('lang');
    if (lang == undefined) {
      localStorage.setItem('lang', 'en');
    } else {
      localStorage.setItem('lang', lang);
    }
  }
  title = 'asimut';
}
