<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="network-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class=" headertext center">{{ "A GLOBAL" | translate }}</p>
      <p class="subheadertext center">{{ "DISTRIBUTION NETWORK" | translate }}</p>
    </div>
  </div>
</div>

<div class="row zeromargin blackbg top10 bot15" dir="{{ 'dir' | translate }}">
  <div class="col-12 sidepadding">
    <div class="row zeromargin">
      <div class="col-lg-9 col-md-12 col-sm-12 col-12 zeropadding">
        <img class="mapimgsize" src="./assets/images/mappa-headquarter.png" alt="">
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12 col-12  zeropadding">
        <div class="locationbox">
          <p class="center countryfont">{{ "Cairo" | translate }}</p>
          <div class="borderbot"></div>
          <p class="center locationname top10">{{ "AZIMUT EGYPT ASSET MANAGEMENT" | translate }}</p>
          <div class="row zeromargin top3">
            <div class="col-12 center">
              <span class="locationkey">{{ "Address" | translate }} :</span><span class="locationvalue">{{ "Smart Village | Building B 16 | PO-Box 12577 | Giza, Egypt" | translate }}</span>
            </div>
          </div>
          <div class="row zeromargin top3">
            <div class="col-12 center">
              <span class="locationkey">{{ "Hours" | translate }} : </span><span class="locationvalue">{{ "Sun- Thu / 08:00 - 16:00" | translate }} </span>
            </div>
          </div>
          <div class="row zeromargin top3">
            <div class="col-12 center">
              <span class="locationkey">{{ "Phone" | translate }} :</span><a href="tel:+20235353535"><span class="locationvalue ltrdirection">+20235353535</span></a>
            </div>
          </div>
          <a href="https://goo.gl/maps/FcfbJhGNJkucBVSe9"><p class="center top25 direction">{{ "Get directions" | translate }}</p></a>
        </div>
      </div>
    </div>
  </div>
</div>

    <nz-tabset [nzSelectedIndex]="selectedTab" class="sliderdirection">
    <nz-tab *ngFor="let item of listconinent" nzTitle="{{item.name}}">
      <div class="row zeromargin ">
        <div class="col-lg-3 col-md-12 col-sm-12 col-12 top20" *ngFor="let itemone of item.branches">
          <div class="locationbox1">
            <p class="center mbot7">{{itemone.country}}</p>
            <p class="center countryfont1">{{itemone.city}}</p>
            <div class="borderbot"></div>
            <p class="center locationname1 top10">{{itemone.name}}</p>
            <div class="row zeromargin ">
              <div class="col-12 minheight center">
                <span class="locationkey1">{{ "Address" | translate }} :</span><span class="locationvalue"> {{itemone.address}}</span>
              </div>
            </div>
            <div class="row zeromargin ">
              <div class="col-12 center">
                <span class="locationkey">{{ "Phone" | translate }} :</span><span class="locationvalue"> {{itemone.phone}}</span>
              </div>
            </div>
            <a href="{{itemone.location}}" target="_blank"><p class="center direction absolutedirection">{{ "Get directions" | translate }}</p></a>
          </div>
        </div>
      </div>
    </nz-tab>
    </nz-tabset>





<app-footer></app-footer>
