import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhoweareService {

  constructor(private http: HttpClient) { }

  getmilestone(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/milestones");
  }
  gettopclients(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/clients");
  }
  getheaders(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/headers");
  }
  getdirectors(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/directors");
  }
}
