import { Component, OnInit } from '@angular/core';
import { SliderService } from './service/slider.service';
import { slider } from './data/data';
import * as $ from 'jquery';
window['jQuery'] = window['$'] = $;

@Component({
  selector: 'app-homeverticalslider',
  templateUrl: './homeverticalslider.component.html',
  styleUrls: ['./homeverticalslider.component.css'],
})
export class HomeverticalsliderComponent implements OnInit {
  constructor(private _fundsApiService: SliderService) {}

  listslider: slider[];
  jQuery: any;
  ngOnInit(): void {
    this._fundsApiService.getSliders().subscribe((data) => {
      if (data.status.code == 200) {
        this.listslider = data.data;
        setTimeout(() => {
          this.test();
        }, 2000);
      }
    });
  }

  // ngAfterContentChecked(): void {
  
  // }

  test = () => {
    (<any>$.fn)('#myCarousel').carousel({
      interval: false,
    });

    //scroll slides on swipe for touch enabled devices
    $('#myCarousel').on('touchstart', function (event) {
      var yClick = event.originalEvent.touches[0].pageY;
      $(this).one('touchmove', function (event) {
        var yMove = event.originalEvent.touches[0].pageY;
        if (Math.floor(yClick - yMove) > 1) {
          (<any>$.fn)('.carousel').carousel('next');
        } else if (Math.floor(yClick - yMove) < -1) {
          (<any>$.fn)('.carousel').carousel('prev');
        }
      });
      $('.carousel').on('touchend', function () {
        $(this).off('touchmove');
      });
    });
    
  };
}
