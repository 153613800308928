import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {



  constructor(private http: HttpClient) { }

  getReports(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/reports");
  }

  getNews(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/news");
  }

  getVideos(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/publications");
  }
  getheaders(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/headers");
  }
  getawards(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/awards");
  }
}
