import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CareersService {

  constructor(private http: HttpClient) { }

  getcareers(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/careers");
  }
  
  getheaders(): Observable<any> {
    return this.http.get(environment.apiUrl+"/list/headers");
  }
}
