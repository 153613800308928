<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="funds-cont">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">{{ "OUR FUNDS" | translate }}</p>
      <p class="subheadertext center">
        {{ "DISCOVER OUR ASSET CLASSES AND RELATED FUNDS" | translate }}
      </p>
    </div>
  </div>
</div>

<div class="row zeromargin blackbg" dir="{{ 'dir' | translate }}">
  <div class="col-12 areapadding">
    <div class="row zeromargin clear_all_filters Rcolpadding">
      <div class="row zeromargin tooltipTitle_holder">
        <span class="blue14L">{{ "ABOUT" | translate }}</span
        ><span class="white14SB left3">{{ tooltipTitleName | translate }}</span>

        <div
          class="col-2 zeropadding"
          nzTooltipTitle="{{ tooltipTitle }}"
          nz-tooltip
        >
          <div class="circle center">
            <p class="white14SB">!</p>
          </div>
        </div>
      </div>

      <div class="Rtop20">
        <select
          [(ngModel)]="applyFilter.assetName"
          class="selectstyle blue12"
          name=""
          (change)="selectFundEquity()"
        >
          <option value="all">{{ "all" | translate }}</option>
          <option
            class="blue12"
            value="{{ asset.id }}"
            *ngFor="let asset of assets"
          >
            {{ asset.name }}
          </option>
        </select>
      </div>

      <div class="clear_all_filterss textdirection" (click)="clearAllFilters()">
        {{ "clear all filters" | translate }}
      </div>
    </div>

    <div class="row zeromargin top501 search_holder">
      <div class="col-3 hideinmedia zeropadding">
        <div class="row zeromargin">
          <div class="col-2 zeropadding">
            <img src="./assets/images/filter.svg" alt="" />
          </div>
          <div class="col-9 zeropadding textdirection">
            <button
              class="advanced_filter"
              data-toggle="collapse"
              data-target="#demo"
            >
              <span class="img_filter">{{
                "ADVANCED FILTERS" | translate
              }}</span>

              <img
                class="arrow_filter"
                src="./assets/images/arrow_select.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="row zeromargin">
          <div
            class="col-lg-4 col-md-4 col-sm-4 col-4 zeropadding select_holder"
          >
            <select
              class="selectstyle2 white11SB"
              [(ngModel)]="applyFilter.fundOption"
              name=""
            >
              <option class="white13B" value="fund_name">
                {{ "FUND NAME" | translate }}
              </option>
              <option class="white13B" value="ric">
                {{ "RIC" | translate }}
              </option>
              <option class="white13B" value="bbg">
                {{ "BBG" | translate }}
              </option>
              <option class="white13B" value="subscription">
                {{ "SUBSCRIPTION" | translate }}
              </option>
              <option class="white13B" value="redemption">
                {{ "REDMPTION" | translate }}
              </option>
            </select>
          </div>

          <div class="col-lg-8 col-md-8 col-sm-8 col-8 zeropadding">
            <div class="border1">
              <div class="row zeromargin">
                <div class="col-10">
                  <input
                    class="inputsyle"
                    type="text"
                    name=""
                    value=""
                    [(ngModel)]="applyFilter.fundText"
                    placeholder="{{ 'Search' | translate }}"
                    (keyup.enter)="applyFilterMethod()"
                  />
                </div>
                <div class="col-2">
                  <button
                    class="submit"
                    (click)="applyFilterMethod()"
                    type="button"
                    name="button"
                  >
                    {{ "SEARCH" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          col-lg-3 col-12-md col-12-sm col-12
          top501
          Rcolpadding
          showinmedia1
          zeropadding
        "
      >
        <div class="row zeromargin textdirection">
          <div class="col-2 zeropadding">
            <img src="./assets/images/filter.svg" alt="" />
          </div>
          <div class="col-9 zeropadding">
            <button
              class="advanced_filter"
              data-toggle="collapse"
              data-target="#demo"
            >
              <span class="img_filter">{{
                "ADVANCED FILTERS" | translate
              }}</span>

              <img
                class="arrow_filter"
                src="./assets/images/arrow_select.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div class="col-3 hideinmedia zeropadding">
        <div class="row zeromargin">
          <div class="col-3 rightfloat">
            <p class="automargin">{{ "view" | translate }}:</p>
          </div>
          <div class="col-9 checkbox_holder">
            <div>
              <span>
                <img
                  *ngIf="grid"
                  class="right10"
                  src="./assets/images/blueGrid.svg"
                  alt=""
                />

                <img
                  *ngIf="!grid"
                  class="right10"
                  src="./assets/images/Group 4928.svg"
                  alt=""
                />
                <span *ngIf="grid" style="color: #0092ff">{{
                  "Grid" | translate
                }}</span>
                <span *ngIf="!grid">{{ "Grid" | translate }}</span>
              </span>
            </div>
            <div>
              <input
                type="checkbox"
                unchecked
                (change)="toGrid($event)"
                hidden="hidden"
                id="username"
              />
              <label class="switch" for="username"></label>
            </div>
            <div class="nav-item">
              <span>
                <img
                  *ngIf="grid"
                  class="right10"
                  src="./assets/images/listGray.svg"
                  alt=""
                />
                <img
                  *ngIf="!grid"
                  class="right10"
                  src="./assets/images/Group 5455.svg"
                  alt=""
                />
                <span *ngIf="!grid" style="color: #0092ff">
                  {{ "List" | translate }}
                </span>
                <span *ngIf="grid">
                  {{ "List" | translate }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="demo" class="collapse textdirection">
      <div class="row zeromargin filter_holder">
        <div class="col-12 zeropadding">
          <h3 class="filter-title-text">{{ "Filter by" | translate }}:</h3>
        </div>

        <div class="col-12 zeropadding lables_holder">
          <div class="col-3 textdirection">
            <h3 class="filter-title-sub">{{ "currency" | translate }}</h3>
          </div>
          <div class="hideinmedia">
            <h3 class="filter-title-sub">{{ "nav" | translate }}</h3>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-12 col-sm-12 col-12 zeropadding radio_holder"
        >
          <nz-radio-group
            [(ngModel)]="currency_id"
            (ngModelChange)="getSelctedCurrency($event)"
            nzName="radiogroup"
          >
            <label
              nz-radio
              nzValue="{{ item.id }}"
              *ngFor="let item of currencies"
              >{{ item.symbol }}</label
            >
          </nz-radio-group>
        </div>

        <div
          class="col-lg-7 col-md-12 col-sm-12 col-12 currency_holder"
          *ngIf="showRange"
        >
          <div class="range_holder">
            <ng5-slider
              [(value)]="value"
              [(highValue)]="highValue"
              [options]="options"
            ></ng5-slider>
          </div>
          <div>
            <button class="apply_filter_b" (click)="applyFilterMethod()">
              {{ "apply filter" | translate }}
            </button>
            <button class="clear_filter" (click)="clearAllFilters()">
              {{ "clear filter" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="emptyState" class="empty_state">
      {{ "No Results Found" | translate }}
    </div>
    <div *ngIf="!emptyState" class="hideinmedia">
      <div *ngIf="grid == false">
        <div class="row zeromargin graybg mtop35 hideinmedia">
          <div class="col-12 fundspadding">
            <div class="row zeromargin rowalign">
              <div class="col-1"></div>
              <div class="col-6 zeropadding">
                <div class="row zeromargin">
                  <div class="col-4 top7">
                    <p class="white11B center">{{ "Fund name" | translate }}</p>
                  </div>
                  <div class="col-2">
                    <p class="white11B zeromargin center">
                      {{ "Currency" | translate }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="white11B zeromargin center">
                      {{ "Type" | translate }}
                    </p>
                  </div>
                  <div class="col-3 top7">
                    <p class="white11B center">{{ "RIC" | translate }}</p>
                  </div>
                </div>
              </div>

              <div class="col-4 zeropadding">
                <div class="row zeromargin rowalign">
                  <div class="col-3 top7">
                    <p class="white11B center">
                      {{ "BBG Ticker" | translate }}
                    </p>
                  </div>
                  <div class="col-3 top7">
                    <p class="white11B center">
                      {{ "Subscription" | translate }}
                    </p>
                  </div>
                  <div class="col-3 top7">
                    <p class="white11B center">
                      {{ "Redemption" | translate }}
                    </p>
                  </div>
                  <div class="col-3 top7">
                    <p class="white11B center">{{ "NAV" | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="col-1"></div>
            </div>

            <div class="row zeromargin top5">
              <div class="col-12 zeropadding">
                <div class="borderbottom"></div>
              </div>
            </div>
            <div *ngFor="let fund of listfunds; let i = index">
              <div class="row zeromargin top5 rowalign">
                <div class="col-1 colcenter">
                  <img
                    class="leftfloat arrowrotate"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />

                  <img
                    *ngIf="fund.selected == true"
                    class="rightfloat star"
                    src="./assets/images/Group 5124.svg"
                    alt=""
                    (click)="addToWatchList(fund.slug)"
                  />

                  <img
                    *ngIf="fund.selected == false"
                    class="rightfloat star"
                    src="./assets/images/star.svg"
                    alt=""
                    (click)="addToWatchList(fund.slug)"
                  />
                </div>
                <div class="col-6 zeropadding">
                  <div class="row zeromargin rowalign">
                    <div class="col-4">
                      <p class="white14B center">{{ fund.name }}</p>
                    </div>
                    <div class="col-2">
                      <p class="white14B zeromargin center">
                        {{ fund.currency.symbol }}
                      </p>
                    </div>
                    <div class="col-3">
                      <p class="white14B zeromargin center">
                        {{ fund.asset.name }}
                      </p>
                    </div>
                    <div class="col-3">
                      <p class="white14B center">{{ fund.RIC }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-4 zeropadding">
                  <div class="row zeromargin rowalign">
                    <div class="col-3">
                      <p class="white14B center nowrap">{{ fund.bbg }}</p>
                    </div>
                    <div class="col-3">
                      <p class="white14B center">{{ fund.subscription }}</p>
                    </div>
                    <div class="col-3">
                      <p class="white14B center">{{ fund.redemption }}</p>
                    </div>
                    <div class="col-3">
                      <p class="white14B center whitewrap">
                        {{ fund?.last_nav == null ? " -" : fund?.last_nav.nav }}
                        {{ fund.currency.symbol }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-1 zeropadding">
                  <button
                    class="showbuttonstyle"
                    type="button"
                    name="button"
                    (click)="gotoDynamic(fund.slug)"
                  >
                    {{ "Show Details" | translate }}
                  </button>
                </div>
              </div>

              <div class="row zeromargin top5">
                <div class="col-12 zeropadding">
                  <div class="borderbottom"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="grid == true">
        <div class="row zeromargin mtop35">
          <div class="col-12 zeropadding">
            <div class="row zeromargin top20">
              <div
                class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop10 textdirection"
                *ngFor="let fund of listfunds; let i = index"
              >
                <div class="lightgraybg gridboxpadding">
                  <div class="row zeromargin">
                    <div class="col-9">
                      <p class="white13B" title="{{ fund.name }}">
                        {{ fund.name }}
                      </p>
                    </div>
                    <div class="col-3">
                      <p class="blue13SB">{{ fund.currency.symbol }}</p>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top50 flex">
                      <span class="text1">{{ "Type" | translate }} : </span>
                      <span class="text2"> {{ fund.asset.name }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1">{{ "RIC" | translate }} : </span>
                      <span class="text2"> {{ fund.RIC }}</span>
                    </div>
                  </div>

                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1"
                        >{{ "BBG Ticker" | translate }} :
                      </span>
                      <span class="text2"> {{ fund.bbg }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex felxcenter">
                      <span class="text1"
                        >{{ "Redemption" | translate }} :
                      </span>
                      <span class="text2 texttt" title="{{ fund.redemption }}">
                        {{ fund.redemption }}</span
                      >
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1"
                        >{{ "Subscription" | translate }} :
                      </span>
                      <span class="text2"> {{ fund.subscription }}</span>
                    </div>
                  </div>
                  <div class="row zeromargin">
                    <div class="col-12 top10 flex">
                      <span class="text1">{{ "NAV" | translate }} : </span>
                      <span class="text2">
                        {{ fund?.last_nav == null ? " -" : fund?.last_nav.nav }}
                        {{ fund.currency.symbol }}</span
                      >
                    </div>
                  </div>
                  <div class="row zeromargin top23">
                    <div class="col-2 colalign">
                      <img
                        *ngIf="fund.selected == true"
                        class="rightfloat11 star"
                        src="./assets/images/Group 5124.svg"
                        alt=""
                        (click)="addToWatchList(fund.slug)"
                      />

                      <div class="hover_star" *ngIf="fund.selected == false">
                        <img
                          class="rightfloat11 star"
                          src="./assets/images/star.svg"
                          alt=""
                          (click)="addToWatchList(fund.slug)"
                        />

                        <img
                          class="rightfloat11 selectedStar"
                          src="./assets/images/Group 5124.svg"
                          alt=""
                          (click)="addToWatchList(fund.slug)"
                        />
                      </div>
                    </div>
                    <div class="col-10">
                      <button
                        class="showbuttonstyle"
                        type="button"
                        (click)="gotoDynamic(fund.slug)"
                        name="button"
                      >
                        {{ "Show Details" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="showinmedia">
      <div class="row zeromargin mtop35">
        <div class="col-12 zeropadding">
          <div class="row zeromargin top20">
            <div
              class="col-lg-3 col-md-12 col-sm-12 col-12 Rtop10 textdirection"
              *ngFor="let fund of listfunds; let i = index"
            >
              <div class="lightgraybg gridboxpadding">
                <div class="row zeromargin">
                  <div class="col-9">
                    <p class="white13B" title="{{ fund.name }}">
                      {{ fund.name }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="blue13SB">{{ fund.currency.symbol }}</p>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top50 flex">
                    <span class="text1">{{ "Type" | translate }} : </span>
                    <span class="text2"> {{ fund.asset.name }}</span>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top10 flex">
                    <span class="text1">{{ "RIC" | translate }} : </span>
                    <span class="text2"> {{ fund.RIC }}</span>
                  </div>
                </div>

                <div class="row zeromargin">
                  <div class="col-12 top10 flex">
                    <span class="text1">{{ "BBG Ticker" | translate }} : </span>
                    <span class="text2"> {{ fund.bbg }}</span>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top10 flex felxcenter">
                    <span class="text1">{{ "Redemption" | translate }} : </span>
                    <span class="text2 texttt" title="{{ fund.redemption }}">
                      {{ fund.redemption }}</span
                    >
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top10 flex">
                    <span class="text1"
                      >{{ "Subscription" | translate }} :
                    </span>
                    <span class="text2"> {{ fund.subscription }}</span>
                  </div>
                </div>
                <div class="row zeromargin">
                  <div class="col-12 top10 flex">
                    <span class="text1">{{ "NAV" | translate }} : </span>
                    <span class="text2">
                      {{ fund?.last_nav == null ? " -" : fund?.last_nav.nav }}
                      {{ fund.currency.symbol }}</span
                    >
                  </div>
                </div>
                <div class="row zeromargin top23">
                  <div class="col-2 colalign">
                    <img
                      *ngIf="fund.selected == true"
                      class="rightfloat11 star"
                      src="./assets/images/Group 5124.svg"
                      alt=""
                      (click)="addToWatchList(fund.slug)"
                    />

                    <div class="hover_star" *ngIf="fund.selected == false">
                      <img
                        class="rightfloat11 star"
                        src="./assets/images/star.svg"
                        alt=""
                        (click)="addToWatchList(fund.slug)"
                      />

                      <img
                        class="rightfloat11 selectedStar"
                        src="./assets/images/Group 5124.svg"
                        alt=""
                        (click)="addToWatchList(fund.slug)"
                      />
                    </div>
                  </div>
                  <div class="col-10">
                    <button
                      class="showbuttonstyle"
                      type="button"
                      (click)="gotoDynamic(fund.slug)"
                      name="button"
                    >
                      {{ "Show Details" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
