<app-header></app-header>

<div class="loader" *ngIf="loader">
  <i nz-icon class="spinner" nzType="loading" nzTheme="outline"></i>
</div>

<div class="expertise-cont" dir="{{ 'dir' | translate }}">
  <div class="row zeromargin">
    <div class="col-12 top120">
      <p class="headertext center">{{ "OUR EXPERTISE" | translate }}</p>
      <p class="subheadertext center">
        {{
          "Performance, Knowledge and up-to-date investment style" | translate
        }}
      </p>
    </div>
  </div>
</div>

<!-- <nz-tabset [nzSelectedIndex]="selectedTab" class="sliderdirection">
<nz-tab *ngFor="let experties of listHeaders; let i = index"  nzTitle="{{experties.name}}">
  <div class="row zeromargin sidepadding">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <p class="header textdirection" >{{experties.name}}</p>

      <div class="expertise textdirection" [innerHtml]="experties.content">
      </div>
    </div>
  </div>
</nz-tab>

</nz-tabset> -->

<mat-tab-group [selectedIndex]="selectedTab" mat-align-tabs="center" dir="{{ 'dir' | translate }}">
  <mat-tab
    *ngFor="let experties of listHeaders; let i = index"
    label="{{ experties.name }}"
  >
    <div class="row zeromargin sidepadding semiblackbg">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <p class="header textdirection">{{ experties.name }}</p>

        <div
          class="expertise textdirection"
          [innerHtml]="experties.content"
        ></div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<app-footer></app-footer>
