import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
} from 'ng-apexcharts';
import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
} from 'ng-apexcharts';
import { data } from './data/data';
import { Router, ActivatedRoute } from '@angular/router';
import { FundpageService } from './service/fundpage.service';
import { EachFund } from './data/data';
import { detailsList } from './data/data';
import { ToastrService } from 'ngx-toastr';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: any;
  dataLabels: ApexDataLabels;
  fill: {
    colors: any;
  };
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  toolbar: any;
  grid: any;
  colors: any;
  fill: {
    colors: any;
  };
};

export type ChartOptions3 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
};

@Component({
  selector: 'app-fundpage',
  templateUrl: './fundpage.component.html',
  styleUrls: ['./fundpage.component.css'],
})
export class FundpageComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions2: Partial<ChartOptions2>;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions3: Partial<ChartOptions3>;
  public activeOptionButton = 'all';
  public watchList: boolean = false;
  public cash: number = 0;
  public stocks: number = 0;
  public bonds: number = 0;
  public other: number = 0;
  public not_classified: number = 0;
  public files: any;
  public filesList: any;
  public startDate1: any = ' ';
  public endDate1: any = ' ';
  public startDate2: any = ' ';
  public endDate2: any = ' ';
  public startDate3: any = ' ';
  public endDate3: any = ' ';
  public startDate4: any = ' ';
  public endDate4: any = ' ';
  public startDate5: any = ' ';
  public endDate5: any = ' ';
  public startDate6: any = ' ';
  public endDate6: any = ' ';
  public filesNames: any;
  public pdfURL = "";

  public emailName: any = '';
  public chartValuesTotal: any = 100;
  tooltipTitleName: any;
  chartKey = [];
  chartValues = [];
  chartObject = [];
  barchartValues = [];
  barchartKeys = [];
  chartColors = [];
  date: any;
  constructor(
    private _eachfundApiService: FundpageService,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService
  ) {}

  loader: boolean = true;
  details: detailsList[];
  listeachfund: EachFund;
  graph: any;
  isAndroid = window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
  allowDownload: boolean = false;

  initChart2(): void {
    let that = this;
    this.chartOptions = {
      series: this.chartValues,
      chart: {
        type: 'donut',
        width: 250,
        height: 250,
      },
      labels: this.chartKey,
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return ((val * that.chartValuesTotal) / 100).toFixed(2);;
        },
      },
      responsive: [
        {
          breakpoint: 100,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      colors: this.chartColors,
    };

    this.chartOptions3 = {
      series: [
        {
          name: 'basic',
          data: this.barchartValues,
        },
      ],
      chart: {
        type: 'bar',
        height: 250,
      },

      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.barchartKeys,
        max: 100,
      },
      yaxis: {
        max: 100,
      },
    };
  }

  initChart(): void {
    this.chartOptions2 = {
      series: [
        {
          name: this.tooltipTitleName,
          data: this.graph,
        },
      ],
      chart: {
        type: 'area',
        height: 350,
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: '#999',
            label: {
              text: 'Support',
              style: {
                color: '#fff',
                background: '#00E396',
              },
            },
          },
        ],
        xaxis: [
          {
            x: new Date('14 Nov 2012').getTime(),
            borderColor: '#999',
            label: {
              text: 'Rally',
              style: {
                color: '#fff',
                background: '#775DD0',
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(this.date).getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
      },
    };
  }

  public updateOptionsData = {
    '1m': {
      xaxis: {
        min: new Date(this.startDate1).getTime(),
        max: new Date(this.endDate1).getTime(),
      },
    },
    '3m': {
      xaxis: {
        min: new Date(this.startDate2).getTime(),
        max: new Date(this.endDate2).getTime(),
      },
    },
    '6m': {
      xaxis: {
        min: new Date(this.startDate3).getTime(),
        max: new Date(this.endDate3).getTime(),
      },
    },
    '1y': {
      xaxis: {
        min: new Date(this.startDate4).getTime(),
        max: new Date(this.endDate4).getTime(),
      },
    },
    '5y': {
      xaxis: {
        min: new Date(this.startDate5).getTime(),
        max: new Date(this.endDate5).getTime(),
      },
    },
    '10y': {
      xaxis: {
        min: new Date(this.startDate6).getTime(),
        max: new Date(this.endDate6).getTime(),
      },
    },
    all: {
      xaxis: {
        min: undefined,
        max: undefined,
      },
    },
  };

  public updateOptions(option: any): void {
    this.activeOptionButton = option;
    this.chart.updateOptions(this.updateOptionsData[option], false, true, true);
  }
  toINFO() {
    document.getElementById('INFO').scrollIntoView();
  }
  toOBJECTIVE() {
    document.getElementById('OBJECTIVE').scrollIntoView();
  }
  totype() {
    document.getElementById('FUNDDETAILS').scrollIntoView();
  }
  toPERFORMANCE() {
    document.getElementById('PERFORMANCE').scrollIntoView();
  }
  toENTITIES() {
    document.getElementById('ENTITIES').scrollIntoView();
  }
  toMANAGER() {
    document.getElementById('MANAGER').scrollIntoView();
  }
  toDOCUMENTS() {
    document.getElementById('DOCUMENTS').scrollIntoView();
  }

  log(value): void {
    let customFilesList = value.map((item) => {
      return {
        name: item.split('/').pop(),
        path: item.replace('/' + item.split('/').pop(), ''),
      };
    });

    let fileNames = customFilesList.map((item) => {
      return item.name;
    });

    let filePaths = customFilesList.map((item) => {
      return item.path;
    });

    this.filesList = filePaths;
    this.filesNames = customFilesList;

    if (value.length != 0) {
      this.allowDownload = true;
    } else {
      this.allowDownload = false;
    }
  }

  log2(): void {
    this.filesList.map((item) => {
      if (this.isAndroid) {
        this.pdfURL = "https://docs.google.com/viewer?url="+ item + "&embedded=true";
      } else {
        this.pdfURL = item;
      }
      window.open(this.pdfURL, '_blank');
    });
  }

  sendmail() {
    document.getElementById('openmodal').click();
  }

  submitMail() {
    this.loader = true;

    var formData = new FormData();
    var index = 0;

    formData.append('email', this.emailName);
    this.filesList.forEach(function (item) {
      formData.append('pdf[' + index + ']', item);
      index++;
    });

    this._eachfundApiService.submitEmail(formData).subscribe((data) => {
      if (data['status'].code == '200') {
        this.toastr.success(data['status'].message);
        this.loader = false;
      } else {
        this.toastr.error(data['status'].message);
        this.loader = false;
      }
    });
  }

  addToWatchList = () => {
    let requestId = this.route.snapshot.paramMap.get('name');
    let watchList = JSON.parse(localStorage.getItem('list'));
    watchList.push(requestId);
    localStorage.setItem('list', JSON.stringify(watchList));
    setTimeout(() => {
      location.reload();
    }, 1000);
  };

  ngOnInit(): void {
    let lang = localStorage.getItem('lang');

    if (lang == 'en') {
      this.tooltipTitleName = 'Nav';
    } else {
      this.tooltipTitleName = 'سعر الوثيقة ';
    }

    window.scrollTo(0, 0);

    let requestId = this.route.snapshot.paramMap.get('name');
    let itemsList = JSON.parse(localStorage.getItem('list'));

    if (itemsList.includes(requestId)) {
      this.watchList = true;
    } else {
      this.watchList = false;
    }

    this._eachfundApiService.getfund(requestId).subscribe((data) => {
      this.loader = true;

      if (data.status.code == 200) {
        this.date = data.data.date;
        this.chartKey = data.data.allocation.keys;
        this.chartValues = data.data.allocation.values;
        this.barchartValues = data.data.allocation.bar_values;
        this.barchartKeys = data.data.allocation.bar_keys;

        this.chartValuesTotal = data.data.allocation.values.reduce(
          (a, b) => a + b,
          0
        );
        this.chartObject = data.data.allocation.allocation;
        this.chartColors = data.data.allocation.colors;
        this.loader = false;
        this.graph = data.data.graph;
        this.details = data.data.details;
        this.listeachfund = data.data;
        this.files = data.data.files;
        this.updateOptionsData = {
          '1m': {
            xaxis: {
              min: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[0]
                ].start_date.toString()
              ).getTime(),
              max: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[0]
                ].end_date.toString()
              ).getTime(),
            },
          },
          '3m': {
            xaxis: {
              min: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[2]
                ].start_date.toString()
              ).getTime(),
              max: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[2]
                ].end_date.toString()
              ).getTime(),
            },
          },
          '6m': {
            xaxis: {
              min: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[4]
                ].start_date.toString()
              ).getTime(),
              max: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[4]
                ].end_date.toString()
              ).getTime(),
            },
          },
          '1y': {
            xaxis: {
              min: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[1]
                ].start_date.toString()
              ).getTime(),
              max: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[1]
                ].end_date.toString()
              ).getTime(),
            },
          },
          '5y': {
            xaxis: {
              min: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[3]
                ].start_date.toString()
              ).getTime(),
              max: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[3]
                ].end_date.toString()
              ).getTime(),
            },
          },
          '10y': {
            xaxis: {
              min: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[5]
                ].start_date.toString()
              ).getTime(),
              max: new Date(
                data.data.periods[
                  Object.keys(data.data.periods)[5]
                ].end_date.toString()
              ).getTime(),
            },
          },
          all: {
            xaxis: {
              min: undefined,
              max: undefined,
            },
          },
        };

        setTimeout(() => {
          this.initChart();
          this.initChart2();
        }, 1000);
      }
    });
  }

  // ngAfterViewInit() {
  //   document.addEventListener('keydown', KeyCheck);
  //   function KeyCheck(event) {
  //     var KeyID = event.keyCode;
  //     switch (KeyID) {
  //       case 8:
  //         window.open('/funds', '_Self');
  //         break;
  //       case 46:
  //         console.log('delete');
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }
}
